import {Button, Card, Form, ListGroup, Row, Spinner, Table} from "react-bootstrap";
import {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {TsAdminContext} from "../../context/ts-admin";
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify";
import {getOrgInfo, updateOrg} from "../../api-service/org-info";
import {ENVIRONMENT_TYPE_PRODUCT, generateUrlLoginSf} from "../../common";

export function TsConnectPage() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [showModalDisconnect, setShowModalDisconnect] = useState(false);

    useEffect(() => {
        if (orgInfo && orgInfo.currentOrg && orgInfo.currentOrg.AccessToken) {
            setIsConnected(orgInfo.currentOrg.AccessToken !== "");
        }
    }, [orgInfo])


    const disconnectOrg = () => {

        if (orgInfo.currentOrg.AccessToken && orgInfo.currentOrg.AccessToken !== "" && !userInfo.isSuperAdmin) {
            setShowModalDisconnect(true);
        }

    }
    const connectOrg = () => {
        if (isConnected || (orgInfo.currentOrg.AccessToken && orgInfo.currentOrg.AccessToken !== "") || userInfo.isSuperAdmin) {
            return;
        }
        const envLogin = localStorage.getItem('environment-type') || ENVIRONMENT_TYPE_PRODUCT;
        const urlConnectSalesForce = generateUrlLoginSf('token', Number(envLogin));
        var win: any = window.open(urlConnectSalesForce, '', "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=500,width=800,height=700");


        const checkChild = async () => {
            console.log(22);
            if (win.closed) {
                console.log(11);
                win.close();
                clearInterval(timer);
            } else {
                try {
                    if (localStorage.getItem('result-callback-sf')) {
                        const result: any = JSON.parse(localStorage.getItem('result-callback-sf')?.toString() || '');
                        if (result.success) {
                            setIsConnected(true);
                        } else {
                            if (result.message) {
                                const errMessage = result.message;
                                toast.error(errMessage.error);
                            } else {
                                toast.error("存在しない組織の為、接続できません。");
                            }

                        }
                        localStorage.removeItem('result-callback-sf');
                        clearInterval(timer);
                        await getOrgInfo(orgInfo.currentOrg.OrgId)
                            .then((data: any) => {
                                setOrgInfo(data)
                            });
                        win.close();
                    }
                } catch (e) {
                    localStorage.removeItem('result-callback-sf');
                    console.log(e)
                }
            }
        }
        var timer = setInterval(checkChild, 500);
        /*        const pollTimer = window.setInterval(async (e) => {
                    if(window.closed) {
                        console.log(1);
                        try {
                            if (localStorage.getItem('result-callback-sf')) {
                                const result: any = JSON.parse(localStorage.getItem('result-callback-sf')?.toString() || '');
                                if (result.success) {
                                    setIsConnected(true);
                                } else {
                                    if (result.message) {
                                        const errMessage = result.message;
                                        toast.error(errMessage.error);
                                    } else {
                                        toast.error("存在しない組織の為、接続できません。");
                                    }

                                }
                                localStorage.removeItem('result-callback-sf');
                                clearInterval(pollTimer);
                                await getOrgInfo(orgInfo.currentOrg.OrgId)
                                    .then((data: any) => {
                                        setOrgInfo(data)
                                    });
                                win.close();
                            }
                        } catch (e) {
                            localStorage.removeItem('result-callback-sf');
                            console.log(e)
                        }
                    } else {
                        console.log(1);
                    }

                }, 1500);*/

    }
    const handleCloseModal = () => {
        setShowModalDisconnect(false);
    }

    const handleDisconnect = async () => {
        setIsLoading(true);
        await updateOrg(orgInfo.currentOrg.OrgId, {AccessToken: "", RefreshToken: ""}).then((res: any) => {
            setOrgInfo({...orgInfo, currentOrg: res.currentOrgInfo.Attributes, usedLicenseNumber: "-"});
            setIsConnected(false);
            setIsLoading(false);
        }).catch(err => console.log(err)).finally(() => {
            setShowModalDisconnect(false);
        });

    }

    const getUserConnect = () => {
        if (isConnected) {
            return orgInfo?.userInfo ? `${orgInfo?.userInfo?.display_name}にて接続中` : ''
        } else {
            return '接続なし';
        }
    }

    const checkStatusButton = (statusConnect) => {
        if (userInfo.isSuperAdmin) {
            return 'btn-disconnect not-allowed';
        } else {
            if (statusConnect == 1) {
                return isConnected ? 'btn-disconnect not-allowed' : 'btn-connect';
            } else {
                return !isConnected ? 'btn-disconnect not-allowed' : 'btn-connect'
            }
        }
    }

    const updateTsConnect = () => {
        console.log(isSubmit);
        setIsSubmit(true);
    }

    return (
        <Fragment>
            <Modal className="pt-5" show={showModalDisconnect} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>接続を解除しますがよろしいでしょうか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                        キャンセル
                    </Button>

                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                解除...
                            </Button>
                            :
                            <Button variant="primary" size="sm" type="button" onClick={handleDisconnect}
                                    className="modal-btn">
                                解除
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
            <div className="ts-connect">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">TeamSpirit接続情報</Card.Body>
                </Card>
                <Card className="mt-3 rounded-0">
                    {
                        orgInfo && <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">TeamSpiritとの接続</div>
                                    <div key="inline-radio" className="w-50">
                                        <a className={`d-inline-block text-center text-decoration-none ${checkStatusButton(1)}`}
                                           onClick={connectOrg}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-link-45deg"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                                <path
                                                    d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                            </svg>
                                            {' '}接続する
                                        </a>
                                        <a

                                            className={`d-inline-block mx-5 text-center text-decoration-none ${checkStatusButton(0)}`}
                                            onClick={disconnectOrg}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.07" height="11.107"
                                                 viewBox="0 0 11.07 11.107" fill="currentColor"
                                                 className="mr-5 bi bi-link-45deg">
                                                <path id="パス_829" data-name="パス 829"
                                                      d="M5.532,8.453a1.307,1.307,0,0,1-.415-.069,3.074,3.074,0,0,1-.438-.115.252.252,0,0,0-.231.046l-.092.092a1.6,1.6,0,0,1-2.146.115,1.544,1.544,0,0,1-.069-2.262L3.663,4.737a1.5,1.5,0,0,1,.692-.392,1.4,1.4,0,0,1,.969.069,1.249,1.249,0,0,1,.508.346,1.438,1.438,0,0,1,.208.277.227.227,0,0,0,.369.046l.577-.577a.217.217,0,0,0,.023-.3c-.092-.115-.185-.208-.277-.323a4,4,0,0,0-.485-.392A2.79,2.79,0,0,0,5.3,3.122a2.883,2.883,0,0,0-1.892.3,2.427,2.427,0,0,0-.623.462L1.309,5.361a2.81,2.81,0,0,0-.115,3.9,2.781,2.781,0,0,0,4.015.069l.508-.508C5.832,8.707,5.739,8.476,5.532,8.453ZM9.339,5.2A2.8,2.8,0,0,0,9.27,1.184a2.832,2.832,0,0,0-3.9.115l-.462.462a.232.232,0,0,0,.138.392,3.713,3.713,0,0,1,.854.162.252.252,0,0,0,.231-.046l.092-.092A1.6,1.6,0,0,1,8.37,2.061a1.544,1.544,0,0,1,.069,2.262L6.916,5.845a1.5,1.5,0,0,1-.692.392,1.4,1.4,0,0,1-.969-.069,1.25,1.25,0,0,1-.508-.346,1.025,1.025,0,0,1-.185-.254.227.227,0,0,0-.369-.046l-.554.554a.217.217,0,0,0-.023.3,3.443,3.443,0,0,0,.277.323,3.615,3.615,0,0,0,.485.392,2.79,2.79,0,0,0,.946.369,2.883,2.883,0,0,0,1.892-.3A2.427,2.427,0,0,0,7.839,6.7l1.5-1.5ZM10.4,9.468l1.061-1.062a.223.223,0,0,0,0-.323l-.646-.646a.223.223,0,0,0-.323,0L9.455,8.522,8.463,7.53a.223.223,0,0,0-.323,0l-.646.646a.223.223,0,0,0,0,.323l.992.992-.969.969a.223.223,0,0,0,0,.323l.646.646a.223.223,0,0,0,.323,0l.969-.969L10.493,11.5a.223.223,0,0,0,.323,0l.646-.646a.223.223,0,0,0,0-.323Z"/>
                                            </svg>
                                            {' '}接続解除
                                        </a>
                                    </div>
                                </ListGroup.Item>


                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">接続ステータス</div>
                                    <div className="w-50">
                                        {getUserConnect()}
                                    </div>
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">接続先TeamSpirit種別</div>
                                    <div className="w-50">

                                        {
                                            orgInfo.currentOrg.ConnectType === 1 ? 'TeamSpirit' : 'TeamSpirit EX'
                                        }

                                    </div>
                                </ListGroup.Item>
                                {/*{*/}
                                {/*    orgInfo.currentOrg.ConnectType === 2 &&*/}
                                {/*    <ListGroup.Item className="d-flex align-items-center">*/}
                                {/*        <div className="w-25 py-2 item-label">接続先インターフェース</div>*/}
                                {/*        <div key="inline-radio" className="w-50">*/}

                                {/*            <Form.Check*/}
                                {/*                inline*/}
                                {/*                disabled={!isConnected}*/}
                                {/*                defaultChecked={orgInfo.currentOrg.ConnectInterface == 1}*/}
                                {/*                label={"勤怠打刻"}*/}
                                {/*                name="connectInterface"*/}
                                {/*                type={'radio'}*/}
                                {/*                id={`inline-connectInterface-1`}*/}
                                {/*            />*/}
                                {/*            <Form.Check*/}
                                {/*                inline*/}
                                {/*                disabled={!isConnected}*/}
                                {/*                defaultChecked={orgInfo.currentOrg.ConnectInterface == 1}*/}
                                {/*                label={"入退館（乖離判定）"}*/}
                                {/*                name="connectInterface"*/}
                                {/*                type={'radio'}*/}
                                {/*                id={`inline-connectInterface-1`}*/}
                                {/*            />*/}

                                {/*        </div>*/}
                                {/*    </ListGroup.Item>*/}
                                {/*}*/}


                                {/* <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">接続先インタフェース</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Check
                                            inline
                                            disabled={!isConnected}
                                            defaultChecked={orgInfo.currentOrg.ConnectInterface == 1}
                                            label={"勤怠打刻"}
                                            name="connectInterface"
                                            type={'radio'}
                                            id={`inline-connectInterface-1`}
                                        />
                                    </div>
                                </ListGroup.Item> */}


                                {/*<ListGroup.Item className="d-flex justify-content-center p-4 d-grid gap-5">*/}
                                {/*    <Button type="submit"*/}
                                {/*            className={`${isSubmit ? 'btn-export-disable' : ''}  border px-4`}*/}
                                {/*            size="sm"*/}
                                {/*            onClick={updateTsConnect}*/}
                                {/*            disabled={isSubmit}>*/}
                                {/*        {*/}
                                {/*            isSubmit && <Spinner*/}
                                {/*                as="span"*/}
                                {/*                animation="border"*/}
                                {/*                size="sm"*/}
                                {/*                role="status"*/}
                                {/*                aria-hidden="true"*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*        更新*/}
                                {/*    </Button>*/}
                                {/*</ListGroup.Item>*/}
                            </ListGroup>
                        </Card.Body>
                    }
                </Card>
            </div>
        </Fragment>
    )
}