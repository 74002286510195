export function ErrorMessage({...props}) {
        return (
            <div className={`d-flex align-items-center`}>
                {
                    props.errors && Object.keys(props.errors).length > 0 && props.errors[props.name] && <p className={`error ${props.className}`}>{props.errors[props.name].message}</p>
                }
            </div>

        );

}
