import {useLocation} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import {Header} from "../components/header/header";
import {useCallback, useContext, useEffect, useState} from "react";
import {getOrgInfo} from "../api-service/org-info";
import {TsAdminContext} from "../context/ts-admin";
import {LayoutUser} from "./layout-user";
import {LayoutAdmin} from "./layout-admin";
import {checkUrlIsLayoutAdmin, ENVIRONMENT_TYPE_PRODUCT, ENVIRONMENT_TYPE_SANDBOX} from "../common";
import {useAuth} from "../provider";

export function Layout() {

    const [orgInfo, setOrgInfo] = useContext(TsAdminContext);
    const [isLoading, setIsLoading] = useState(true);

    const fetchOrgInfo = useCallback(async () => {
        if (localStorage.getItem('user-info')) {
            const orgId = JSON.parse(localStorage.getItem('user-info') || '')?.organizationId
            await getOrgInfo(orgId)
                .then((data: any) => {
                    setOrgInfo(data);
                    setIsLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchOrgInfo();
    }, []);
    const envType = localStorage.getItem('environment-type') || ENVIRONMENT_TYPE_PRODUCT;

    const getClassEnv = () => {
        if(localStorage.getItem('environment-type')) {
            const envType = localStorage.getItem('environment-type') || ENVIRONMENT_TYPE_PRODUCT;
            return envType === ENVIRONMENT_TYPE_PRODUCT ? '' : 'env-sandbox-body border-top-0'
        } else {
            if(orgInfo) {
                return orgInfo?.currentOrg?.EnvironmentType === ENVIRONMENT_TYPE_PRODUCT ? '' : 'env-sandbox-body border-top-0'
            }
        }
    }

    let auth = useAuth();
    const logout = async () => {
        await auth.signout(() => {
        });
    }
    return (
        <Container className={localStorage.getItem('access-token') ? 'd-block' : 'd-none'}>
            {
                (envType != ENVIRONMENT_TYPE_PRODUCT) && <Row className={`env-sandbox-header border-0`}>
                    <h4>Sandboxでログイン中  <span className="mx-3">|</span>  <a href="javascript:void(0)" onClick={logout}>ログアウト</a> </h4>
                </Row>
            }
            <Row className={`${getClassEnv()} border-content`}>
                <Header/>
            </Row>
            {
                checkUrlIsLayoutAdmin(useLocation().pathname) ? <LayoutAdmin isLoading={isLoading}/> :
                    <LayoutUser isLoading={isLoading}/>
            }
        </Container>
    );
}