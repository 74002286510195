import {Fragment, useCallback, useEffect, useState} from "react";
import {Button, Card, Form, ListGroup, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {
    CONNECT_TYPE_EX,
    CONNECT_TYPE_V5,
    ENVIRONMENT_TYPE_PRODUCT,
    ENVIRONMENT_TYPE_SANDBOX,
    useYupValidationResolver
} from "../../common";
import ReactDatePicker from "react-datepicker";
import * as yup from "yup";
import {ErrorMessage} from "../../parts/error-message";
import {useParams, useSearchParams} from "react-router-dom";
import {format} from "date-fns";
import {updateOrg} from "../../api-service/org-info";
import {createAccount} from "../../api-service/account";
import {toast} from "react-toastify";


const validationSchema = yup.object({
    AccountId: yup.string().required("取引先IDを入力してください。").matches(/^[a-zA-Z0-9]+$/, '取引先IDを入力してください。').test('len', '取引先IDは18桁で入力してください。', val => val.length === 18),
    AccountName: yup.string().required("取引先名を入力してください。").matches(/^(?!\s+$).*/, '取引先名を入力してください。'),
    LicenseNumber: yup.number().typeError('数字を入力してください。').required("契約ライセンス数を入力してください。").min(1, "契約ライセンス数の最小の値は1です。").max(10 ** 12 - 1, "最大12桁数を入力してください。"),
    StartDate: yup.date().typeError("契約開始日を入力してください。").required("契約開始日を入力してください。"),
    EndDate: yup.date().typeError("契約開始日を入力してください。").min(
        yup.ref('StartDate'),
        "契約終了日は契約開始日の未来時刻を設定してください。"
    ).required("契約終了日を入力してください"),
    OrgId: yup.string().required("組織IDを入力してください。").matches(/^[a-zA-Z0-9]+$/, '組織IDを入力してください。').test('len', '組織IDは18桁または15桁で入力してください。', val => val.length === 18 || val.length === 15),
    Namespace: yup.string().matches(/^[a-zA-Z0-9]*$/, '正しい名前空間を入力してください。'),
});

export function CreateOrganizationsPage() {
    const resolver = useYupValidationResolver(validationSchema);
    const [existsAccount, setExistsAccount] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isUseTSNamespace, setIsUseTSNamespace] = useState(true);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted}
    } = useForm({
        resolver: resolver,
        defaultValues: {
            AccountId: "",
            AccountName: "",
            LicenseNumber: "",
            StartDate: "",
            EndDate: "",
            OrgId: "",
            Namespace: "",
            EnvironmentType: ENVIRONMENT_TYPE_PRODUCT,
            ConnectType: CONNECT_TYPE_V5,
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    const getDefaultNameSpace = (useNamespace, formData: any) => {
        console.log(isUseTSNamespace, 'isUseTSNamespace', formData)
        if (!isUseTSNamespace) {
            return formData.Namespace;
        }
        return formData.ConnectType == CONNECT_TYPE_V5 ? 'teamspirit' : 'tex'

    }

    const submitFormData = async (formData) => {

        let namespace = getDefaultNameSpace(isUseTSNamespace, formData)
        let sendData = {...formData, Namespace: namespace};
        if (formData.OrgId.length === 15) {
            sendData = {...sendData, OrgId: convertOrgId(formData.OrgId).toString()};
        }
        setData({...sendData});
        setShowModalConfirm(true);
    }

    const handleCloseModal = () => {
        setShowModalConfirm(false);
    }

    const registerData = async () => {
        setIsLoading(true);
        const insertData = {...data};
        insertData.StartDate = format(data.StartDate, "yyyy/MM/dd");
        insertData.EndDate = format(data.EndDate, "yyyy/MM/dd");
        insertData.ExistsAccount = existsAccount;
        insertData.Namespace = insertData.Namespace;
        insertData.OrgUrl = "";
        insertData.EnvironmentType = Number(insertData.EnvironmentType);
        insertData.ConnectType = Number(insertData.ConnectType);
        await createAccount(insertData).then((res: any) => {
            toast.success("取引先登録に成功しました。");
            setIsUseTSNamespace(false);
            reset();
            setValue("ConnectType", CONNECT_TYPE_V5)
        }).catch(err => toast.error(err))
            .finally(() => {
                setIsLoading(false);
                setShowModalConfirm(false);
            });
    }
    const {accountId}: any = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (accountId) {
            reset({
                AccountId: accountId,
                AccountName: searchParams.get('AccountName'),
                LicenseNumber: searchParams.get('LicenseNumber'),
                StartDate: format(new Date(Number(searchParams.get('StartDate')) * 1000), "yyyy/MM/dd"),
                EndDate: format(new Date(Number(searchParams.get('EndDate')) * 1000), "yyyy/MM/dd"),
            });
            setExistsAccount(true);
        }
    }, [accountId])

    const listEnv = [{value: ENVIRONMENT_TYPE_SANDBOX, text: "Sandbox"}, {
        value: ENVIRONMENT_TYPE_PRODUCT,
        text: "本番"
    }]

    const convertOrgId = (input) => {
        let output;
        var addon = "";
        for (var block = 0; block < 3; block++) {
            var loop = 0;
            for (var position = 0; position < 5; position++) {
                var current = input.charAt(block * 5 + position);
                if (current >= "A" && current <= "Z")
                    loop += 1 << position;
            }
            addon += "ABCDEFGHIJKLMNOPQRSTUVWXYZ012345".charAt(loop);
        }
        output = (input + addon);
        return output;
    }

    const handleCheckbox = () => {
        setIsUseTSNamespace(!isUseTSNamespace);
        setValue('Namespace', '');
    }

    return (
        <Fragment>
            <Modal className="pt-5" show={showModalConfirm} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>入力した内容で取引先登録をしてよろしいでしょうか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                登録...
                            </Button>
                            :
                            <Button variant="primary" size="sm" className="modal-btn" onClick={registerData}>
                                OK
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
            <div className="create-org">
                <div className="mt-3">
                    <Form onSubmit={handleSubmit(submitFormData)}>
                        <Card className="mt-3 card-container">
                            <Card.Body className="page-title">取引先登録（手動登録）</Card.Body>
                        </Card>
                        <Card className="mt-3 card-container">
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 card-label fw-bold">取引先マスタ</div>
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">取引先ID</div>
                                    {existsAccount ?
                                        <p className="py-2 item-label mb-0">{getValues('AccountId')}</p> :
                                        <div key="inline-radio" className="w-50">
                                            <Form.Control className="w-50" size="sm" maxLength={18}
                                                          {...register(`AccountId`)}
                                            />
                                            {isSubmitted && <ErrorMessage name='AccountId' errors={errors}/>}
                                        </div>
                                    }

                                </ListGroup.Item>


                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">取引先名</div>
                                    {existsAccount ?
                                        <p className="py-2 item-label mb-0">{getValues('AccountName')}</p> :
                                        <div key="inline-radio" className="w-50">

                                            <Form.Control className="w-50" size="sm" maxLength={255}
                                                          {...register(`AccountName`)}
                                            />
                                            {isSubmitted && <ErrorMessage name='AccountName' errors={errors}/>}
                                        </div>
                                    }
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">契約ライセンス数</div>
                                    {existsAccount ?
                                        <p className="py-2 item-label mb-0">{getValues('LicenseNumber')}</p> :
                                        <div key="inline-radio" className="w-50">
                                            <Form.Control className="w-50" size="sm"
                                                          inputMode={'numeric'}
                                                          type="number"
                                                          min={0}
                                                          {...register(`LicenseNumber`)}
                                            />
                                            {isSubmitted && <ErrorMessage name='LicenseNumber' errors={errors}/>}
                                        </div>
                                    }
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">契約開始日</div>
                                    {existsAccount ?
                                        <p className="py-2 item-label mb-0">{format(new Date(Number(searchParams.get('StartDate')) * 1000), "yyyy/MM/dd")}</p> :
                                        <div key="inline-radio" className="w-50">
                                            <Controller
                                                control={control}
                                                name='StartDate'
                                                render={({field}) => (
                                                    <ReactDatePicker
                                                        placeholderText=''
                                                        dateFormat={"yyyy/MM/dd"}
                                                        className="form-control"
                                                        maxDate={getValues('EndDate') ? new Date(getValues('EndDate')) : null}
                                                        onChange={(date) => field.onChange(date)}
                                                        customInput={
                                                            <Form.Control className="w-50" size="sm"
                                                                          type="text"
                                                                          id="startDate"
                                                                          placeholder=""
                                                            />
                                                        }
                                                        selected={field.value ? new Date(field.value) : null}
                                                    />
                                                )}
                                            />
                                            {isSubmitted && <ErrorMessage name='StartDate' errors={errors}/>}

                                        </div>}
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">契約終了日</div>
                                    {existsAccount ?
                                        <p className="py-2 item-label mb-0">{format(new Date(Number(searchParams.get('EndDate')) * 1000), "yyyy/MM/dd")}</p> :
                                        <div key="inline-radio" className="w-50">

                                            <Controller
                                                control={control}
                                                name='EndDate'
                                                render={({field}) => (
                                                    <ReactDatePicker
                                                        placeholderText=''
                                                        dateFormat={"yyyy/MM/dd"}
                                                        className="form-control"
                                                        minDate={getValues('StartDate') ? new Date(getValues('StartDate')) : null}
                                                        onChange={(date) => field.onChange(date)}
                                                        customInput={
                                                            <Form.Control className="w-50" size="sm"
                                                                          type="text"
                                                                          id="endDate"
                                                                          placeholder=""
                                                            />
                                                        }
                                                        selected={field.value ? new Date(field.value) : null}
                                                    />
                                                )}
                                            />
                                            {isSubmitted && <ErrorMessage name='EndDate' errors={errors}/>}
                                        </div>
                                    }
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        <Card className="mt-3 card-container">

                            <ListGroup variant="flush">

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 card-label fw-bold">組織マスタ</div>
                                </ListGroup.Item>

                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">組織ID</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Control className="w-50" size="sm" maxLength={18}
                                                      {...register(`OrgId`)}
                                        />
                                        {isSubmitted && <ErrorMessage name='OrgId' errors={errors}/>}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">システム区分</div>
                                    <div key="inline-radio" className="w-50">

                                        <Form.Check
                                            inline
                                            {...register('ConnectType')}
                                            defaultChecked={getValues('ConnectType') == CONNECT_TYPE_V5}
                                            label={"TeamSpirit"}
                                            onChange={(e) => setValue('ConnectType', e.target.value)}
                                            name="connectType"
                                            type={'radio'}
                                            value={CONNECT_TYPE_V5}
                                            id={`inline-connectType-1`}
                                        />
                                        <Form.Check
                                            inline
                                            {...register('ConnectType')}
                                            label={"TeamSpirit EX"}
                                            onChange={(e) => setValue('ConnectType', e.target.value)}
                                            defaultChecked={getValues('ConnectType') == CONNECT_TYPE_EX}
                                            name="connectType"
                                            type={'radio'}
                                            value={CONNECT_TYPE_EX}
                                            id={`inline-connectType-2`}
                                        />

                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">組織形式</div>
                                    <div key="inline-radio" className="w-50">
                                        <Form.Select className="w-50" {...register('EnvironmentType')}>
                                            {
                                                listEnv.map((e, index) => {
                                                    return <Fragment key={index}>
                                                        <option value={e.value}>{e.text}</option>
                                                    </Fragment>
                                                })

                                            }
                                        </Form.Select>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center">
                                    <div className="w-25 py-2 item-label">名前空間</div>
                                    <div key="inline-radio" className="w-50">
                                        <div className="d-inline-flex align-items-center w-100">
                                            <input
                                                id="custom-namespace-checkbox"
                                                className="admin-form-checkbox"
                                                type="checkbox"
                                                checked={!isUseTSNamespace}
                                                onChange={() => handleCheckbox()}
                                            />
                                            <label className="admin-form-label ps-1 pe-3"
                                                   htmlFor="custom-namespace-checkbox">別の名前空間にする</label>
                                            <Form.Control className="w-50" size="sm"
                                                          {...register(`Namespace`)}
                                                          disabled={isUseTSNamespace}
                                                          maxLength={15}
                                            />
                                        </div>
                                        {isSubmitted && <ErrorMessage name='Namespace' errors={errors}/>}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>


                        </Card>
                        <div className='mb-3 d-flex align-items-center'>
                            <Button type="submit" size="sm"
                                    disabled={isSubmitting}
                                    className={`border m-auto mt-5 px-4 ${(!isDirty || isSubmitting) ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                                {
                                    isSubmitting && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                                登録
                            </Button>
                        </div>
                    </Form>
                </div>

            </div>
        </Fragment>
    )
}