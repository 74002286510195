import React, {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {
    compareRangeTime, CONNECT_TYPE_V5, convertStringHourToSecond,
    groupBy,
    selectBoxStatusCodeButtonCase2,
    selectBoxStatusCodeButtonCase4,
    selectBoxStatusCodeButtonCase6,
    selectBoxStatusCodeButtonCase99,
    useYupValidationResolver
} from "../../common";
import {
    createQrCodeSetting,
    deleteQrCodeSetting,
    getListSetting,
    updateQrCodeSetting
} from "../../api-service/qr-code-setting";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {Button, ButtonGroup, Card, Col, Form, ListGroup, Row, Spinner} from "react-bootstrap";
import {TsAdminContext} from "../../context/ts-admin";
import {ErrorMessage} from "../../parts/error-message";
import * as yup from "yup";
import {QrPasswordSetting} from "./qr-password-setting";
import {QrLogoSetting} from "./qr-logo-setting";
import {QrCodeReader} from "./qr-code-reader";
import {isIPv4} from 'is-ip';
import {getMatch, IPMatch} from 'ip-matching';
import {inRange} from 'range_check'
import {Tooltip} from "../../parts/tooltip";

const validateNotice = (option, value) => {
    if (option != 2) {
        return true;
    } else {
        return value !== undefined
    }
}
const validateLengthNotice = (option, value, maxLength) => {
    if (option != 2) {
        return true;
    } else {
        return value == undefined || value.length <= maxLength
    }
}

const validationSchema = yup.object({
    DeviceId: yup.string().required("入力してください")
        .trim().transform(value => value === '' ? undefined : value)
        .matches(/^[a-zA-Z0-9]+$/, '10文字以内の半角英数字のみで入力してください。')
        .test('len', '10文字以内の半角英数字のみで入力してください', val => val.length <= 10),
    DeviceNote: yup.string().trim().transform(value => value === '' ? undefined : value)
        .test('len', '30文字以内で入力してくだいさい', val => val == undefined || val.length <= 30),
    WorkLocation: yup.string().trim().transform(value => value === '' ? undefined : value).when("UseWorkLocation", (useWorkLocation, schema) => {
        if (useWorkLocation.length > 0 && useWorkLocation[0] == 1)
            return schema.max(10, "10文字以内で入力してくだいさい")
        return schema
    }),
    MealReservationNotice: yup.object({
        title: yup.string().trim().transform(value => value === '' ? undefined : value).test('required', 'タイトルを入力してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                return validateNotice(parent.MealReservation, value)
            }
            return true;
        }).test('length', 'タイトルは40文字以内で入力してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                return validateLengthNotice(parent.MealReservation, value, 40)
            }
            return true;
        }),
        content: yup.string().trim().transform(value => value === '' ? undefined : value).test('required', 'お知らせの内容を入力してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                return validateNotice(parent.MealReservation, value)
            }
            return true;
        }).test('required', 'お知らせの内容は120文字以内で入力してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                return validateLengthNotice(parent.MealReservation, value, 120)
            }
            return true;
        }),
    }),
    MealReservationTime: yup.object({
        startTime: yup.string().trim().transform(value => value === '' ? undefined : value).test('required', '注文可能時間を入力してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                if (parent.MealReservation == 1) {
                    return parent.MealReservationTime.endTime !== '' && parent.MealReservationTime.startTime != ''
                }
            }
            return true;
        }).test('compare', '食事予約の注文可能時間終了時刻は開始時刻の未来時刻を設定してください。', function (value) {
            const {from} = this;
            if (from && from?.length > 1) {
                const parent = from[1].value;
                if (parent.MealReservation == 1 && parent.MealReservationTime.endTime !== '' && parent.MealReservationTime.startTime != '') {
                    return convertStringHourToSecond(parent.MealReservationTime.endTime) > convertStringHourToSecond(parent.MealReservationTime.startTime)
                }
            }
            return true;
        }),
    }),
    MealReservationTitle: yup.object({
        notSelectedMeal: yup.string().trim().transform(value => value === '' ? '' : value).test('length', '20文字以内で入力してくだいさい', function (value) {
            const {from} = this;
            console.log("value ", value)
            if (value && from && from?.length > 1) {
                const parent = from[1].value;
                if (parent.MealReservation == 1) {
                    return !!(value && value.toString().length <= 20)
                }
            }
            return true;
        }),
        selectedMeal: yup.string().trim().transform(value => value === '' ? '' : value).test('length', '20文字以内で入力してくだいさい', function (value) {
            const {from} = this;
            console.log("value ", value)
            if (value && from && from?.length > 1) {
                const parent = from[1].value;
                if (parent.MealReservation == 1) {
                    return !!(value && value.toString().length <= 20)
                }
            }
            return true;
        }),
    })
});

export function QrSettingPage() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const pageTitle = "QRコード打刻設定";
    const resolver = useYupValidationResolver(validationSchema);

    const [isLoading, setIsLoading] = useState(false);
    const [listSettings, setListSettings] = useState<any>([]);
    const [selectedSettings, setSelectedSettings] = useState<any>();
    const [selectedSettingsClone, setSelectedSettingsClone] = useState<any>();

    const selectBoxRenderValue = [
        {value: 2, text: "[出勤][退勤]"}, {value: 4, text: "[出勤][退勤][外出][戻り]"},
        {value: 6, text: "[出勤][退勤][休憩][戻り]"}, {value: 99, text: "手動登録"}
    ]

    const selectBoxStatusCodeButton = [
        {value: 0, text: ""},
        {value: 1, text: "出勤"}, {value: 2, text: "退勤"}, {value: 31, text: "外出"},
        {value: 32, text: "外出戻り"}, {value: 21, text: "休憩"}, {value: 22, text: "休憩戻り"}
    ]

    const defaultSetting = {
        CustomButton: [
            {value: "1", label: ""},
            {value: "2", label: ""},
            {value: "31", label: ""},
            {value: "32", label: ""},
            {value: "21", label: ""},
            {value: "22", label: ""}
        ],
        StatusAutoTerm: [
            {active: '0', no: '1', startTime: '08:00', endTime: '10:00'},
            {active: '0', no: '1', startTime: '16:00', endTime: '20:00'},
        ],
        ClockMode: '1',
        MealReservation: '0',
        UseWorkLocation: '0',
        WorkLocation: '',
        UseTextRatio: '0',
        TextRatio: '',
        SoundMode: '0',
        StatusMode: 6,
        DeviceId: '',
        DeviceNote: '',
        MealReservationOption: [],
        MealReservationNotice: {
            title: "",
            content: "",
        },
        MealReservationTitle: {
            active: false,
            notSelectedMeal: "",
            selectedMeal: "",
        },
        MealReservationTime: {
            startTime: "06:00",
            endTime: "10:00",
        },
        RangeIpRestriction: [],
        UseRangeIpRestriction: '0'

    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        setError,
        clearErrors,
        formState: {isSubmitting, isDirty, isSubmitted, errors}
    } = useForm({
        resolver: resolver
    });

    const {fields: fieldMealReservationOption, append, remove, move} = useFieldArray({
        control,
        name: "MealReservationOption",
    });

    const {
        fields: fieldRangeIpRestriction,
        append: appendRangeIpRestriction,
        remove: removeRangeIpRestriction
    } = useFieldArray({
        control,
        name: "RangeIpRestriction",
    });


    const {fields: fieldStatusAutoTerm}: any = useFieldArray<any>({
        control,
        name: "StatusAutoTerm"
    });

    const {fields: fieldCustomButton}: any = useFieldArray<any>({
        control,
        name: "CustomButton"
    });
    const watchStatusMode = watch("StatusMode");
    const watchStatusAutoTerm = useWatch({control, name: 'StatusAutoTerm'});
    const watchCustomButton = useWatch({control, name: 'CustomButton'});
    const watchUseWorkLocation = useWatch({control, name: 'UseWorkLocation'});
    const watchUseTextRatio = useWatch({control, name: 'UseTextRatio'});
    const watchSoundMode = useWatch({control, name: 'SoundMode'});
    const watchMealReservationOption = useWatch({control, name: 'MealReservationOption'});
    const watchRangeIpRestriction = useWatch({control, name: 'RangeIpRestriction'});
    const watchUseRangeIpRestriction = useWatch({control, name: 'UseRangeIpRestriction'});
    const watchMealReservation = useWatch({control, name: 'MealReservation'});
    const watchMealReservationTitle = useWatch({control, name: 'MealReservationTitle.active'});
    const watchMealReservationTitle1 = useWatch({control, name: 'MealReservationTitle'});


    useEffect(() => {
        console.log(watchMealReservationTitle1);
    }, [watchMealReservationTitle1]);

    useEffect(() => {
        const newErrors: any = [];

        if (watchCustomButton) {
            if (getValues('StatusMode') == '99') {
                const listCustomButton = watchCustomButton.map((e, index) => {
                    e.id = index + 1;
                    return e
                })

                if (checkUniqueStatusButton(watchCustomButton).length > 0)
                    newErrors.push(...checkUniqueStatusButton(listCustomButton))
                if (checkStatusButtonName(watchCustomButton).length > 0)
                    newErrors.push(...checkStatusButtonName(listCustomButton))

                for (let i = 0; i < watchCustomButton.length; i++) {
                    if (watchCustomButton[i].value != '0' && watchCustomButton[i].label == '') {
                        newErrors.push(`ボタン${watchCustomButton[i].id}のカスタムボタン名を入力してください。`)
                    }
                }

            }
        }
        setErrorProvider({
            customButton: newErrors
        });
    }, [watchCustomButton])


    const [errorProvider, setErrorProvider] = useState<any>({
        statusButton: [],
    });

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name) {
                const fName = name.split(".");
                if (type === "change" && fName[0] == 'CustomButton' && fName[2] == 'value' && getValues(name) == '0') {
                    setValue(`CustomButton.${Number(fName[1])}.label`, '')
                }
            }
            if (name === 'StatusMode') {
                setValue('StatusAutoTerm.1.no', '1');
                setValue('StatusAutoTerm.0.no', '1');
                setErrorProvider({
                    ...errorProvider,
                    statusButton: [],
                })
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const [connectType, setConnectType] = useState<number>();
    useEffect(() => {
        const targetOrg = localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId
        if (orgInfo) {
            setConnectType(orgInfo?.currentOrg?.ConnectType);
            getListSetting({}, targetOrg).then((res: any) => {
                if (res.length > 0) {
                    setListSettings(res);
                    setSelectedSettings(res[0]);
                    resetFormData(res[0]);
                }

            })

        }
    }, [orgInfo])


    const checkUniqueStatusButton = (listStatusButton) => {
        // reassign id of listStatusButton with index display on screen
        const listGroupByStatusButton = groupBy(listStatusButton, list => list.value);
        let error: any = []
        listGroupByStatusButton.forEach((row) => {
            if (row.length > 1) {
                let strButtonDuplicate = "";
                for (let i = 0; i < row.length; i++) {
                    if (row[i].value != '0') {
                        strButtonDuplicate += i === 0 ? `ボタン${row[i].id}` : `とボタン${row[i].id}`
                    }
                }
                error.push(`${strButtonDuplicate}に同じ区分が設定されています。異なる区分を設定してください。`)
            }
        })
        return error;
    }

    const resetFormData = (data) => {
        console.log("1234", data)
        let newFormData: any = {
            Id: data.Id,
            DeviceId: data.DeviceId,
            DeviceNote: data.DeviceNote,
            CustomButton: data.CustomButton,
            StatusAutoTerm: data.StatusAutoTerm,
            ClockMode: String(data.ClockMode),
            MealReservation: String(data.MealReservation),
            UseWorkLocation: (data.WorkLocation && data.WorkLocation != '') ? '1' : '0',
            UseTextRatio: (data.TextRatio && data.TextRatio != '') ? '1' : '0',
            SoundMode: String(data.SoundMode),
            StatusMode: String(data.StatusMode),
            MealReservationOption: data.MealReservationOption,
            MealReservationTime: data.MealReservationTime,
            RangeIpRestriction: data.RangeIpRestriction ?? [{
                startIp: '',
                endIp: ''
            }],
            UseRangeIpRestriction: data.RangeIpRestriction && data.RangeIpRestriction.length > 0 ? '1' : '0',
            MealReservationNotice: data.MealReservationNotice ?? {title: '', content: ''},
            MealReservationTitle: data.MealReservationTitle ?? {
                active: false,
                notSelectedMeal: "",
                selectedMeal: "",
            },
        };
        if (newFormData.UseTextRatio == '1') {
            newFormData.TextRatio = data.TextRatio
        }
        newFormData.WorkLocation = newFormData.UseWorkLocation == '1' ? data.WorkLocation : ''
        console.log("newFormData", newFormData)
        reset({...newFormData});
    }

    const getStringLen = (str) => {
        var l = str.length;
        var c;
        var length = 0;
        for (var i = 0; i < l; i++) {
            c = str.charCodeAt(i);
            if (0x0000 <= c && c <= 0x0019) {
                length += 0;
            } else if (0x0020 <= c && c <= 0x1FFF) {
                length += 1;
            } else if (0x2000 <= c && c <= 0xFF60) {
                length += 2;
            } else if (0xFF61 <= c && c <= 0xFF9F) {
                length += 1;
            } else if (0xFFA0 <= c) {
                length += 2;
            }
        }
        return length;
    }

    // 全角文字の最大は10数と半角文字の最大は20数
    const checkStatusButtonName = (listStatusButton) => {
        let error: any = []
        listStatusButton.forEach((row) => {
            if (getStringLen(row.label) > 20) error.push(`ボタン${row.id}のカスタムボタン名は半角20文字以内、全角10文字以内で登録してください。`)
        })
        return error;
    }

    const validateIpRestriction = () => {
        let isError = false;
        const rangeIpRestriction = getValues('RangeIpRestriction');
        for (let i = 0; i < rangeIpRestriction.length; i++) {
            if (rangeIpRestriction[i].startIp == null || rangeIpRestriction[i].startIp.trim() == ''
                || rangeIpRestriction[i].endIp == null || rangeIpRestriction[i].endIp.trim() == ''
            ) {
                setError(`RangeIpRestriction_${i}_startIp`, {
                    message: '正しいIP形式で入力してください',
                    type: 'error'
                });
                isError = true;
            } else {
                if (!isIPv4(rangeIpRestriction[i].startIp) || !isIPv4(rangeIpRestriction[i].endIp)) {
                    setError(`RangeIpRestriction_${i}_startIp`, {
                        message: '正しいIP形式で入力してください',
                        type: 'error'
                    });
                    isError = true;
                } else {
                    try {
                        const fullRange = getMatch(`0.0.0.0-${rangeIpRestriction[i].endIp}`);
                        const listCidr = fullRange.convertToMasks().map((mask: any) => mask.convertToSubnet().toString());
                        // Check EndIp > Start Ip
                        if (!inRange(rangeIpRestriction[i].startIp, listCidr)) {
                            setError(`RangeIpRestriction_${i}_startIp`, {
                                message: '開始と終了のIP範囲に誤りがあります。',
                                type: 'error'
                            });
                            isError = true;
                        }
                    } catch (e) {
                        setError(`RangeIpRestriction_${i}_startIp`, {
                            message: '開始と終了のIP範囲に誤りがあります。',
                            type: 'error'
                        });
                        isError = true;
                    }

                }
            }
        }
        for (let i = 0; i < rangeIpRestriction.length; i++) {
            if (!isError && rangeIpRestriction.length > 1) {
                // Check IP ranges overlap
                for (let j = 0; j < rangeIpRestriction.length; j++) {
                    if (i != j) {
                        let tmpRangeIp: IPMatch = getMatch(`${rangeIpRestriction[j].startIp}-${rangeIpRestriction[j].endIp}`);
                        let tmpListCidr = tmpRangeIp.convertToMasks().map((mask: any) => mask.convertToSubnet().toString());

                        if (inRange(rangeIpRestriction[i].startIp, tmpListCidr) || inRange(rangeIpRestriction[i].endIp, tmpListCidr)) {
                            isError = true;
                            setError('UseRangeIpRestriction', {
                                message: 'IP範囲が重複しています。重複しないように入力してください。',
                                type: 'error'
                            });
                        }
                    }


                }
            }
        }
        return isError;


    }

    const submitFormData = async (data) => {
        console.log("data", data);
        const mealReservationOption = getValues('MealReservationOption');
        clearErrors();
        let errMealReservationOption = 0;
        let errStatusAutoTerm = 0;
        if (mealReservationOption?.length > 0) {
            for (let i = 0; i < mealReservationOption.length; i++) {

                if (!mealReservationOption[i].active && mealReservationOption[i].name.trim() == '') {
                    setError(`MealReservationOption_${i}_name`, {
                        message: '入力してください',
                        type: 'error'
                    });
                    errMealReservationOption++;
                }
                for (let j = i + 1; j < mealReservationOption.length; j++) {
                    if (!mealReservationOption[i].active && mealReservationOption[i].code == mealReservationOption[j].code && mealReservationOption[i].code != '') {
                        setError(`MealReservationOption_${j}_code`, {
                            message: 'コードが重複しています',
                            type: 'error'
                        });
                        errMealReservationOption++;
                    }
                }
                if (!mealReservationOption[i].active && mealReservationOption[i].code.trim() == '') {
                    setError(`MealReservationOption_${i}_code`, {
                        message: '入力してください',
                        type: 'error'
                    });
                    errMealReservationOption++;
                }
            }
        }
        if ((watchStatusAutoTerm[0].active === '1' && watchStatusAutoTerm[1].active === '1') && watchStatusAutoTerm[0].no === watchStatusAutoTerm[1].no) {
            setError(`StatusAutoTerm_2`, {
                message: `ステータスボタン デフォルト設定変更1とステータスボタン デフォルト設定変更2で同じ区分が設定されています。異なる区分を設定してください。`,
                type: 'error'
            });
            errStatusAutoTerm++
        }


        if (watchStatusAutoTerm[0].active === '1' && watchStatusAutoTerm[1].active === '1'
            && (compareRangeTime(watchStatusAutoTerm[0], watchStatusAutoTerm[1]) || compareRangeTime(watchStatusAutoTerm[1], watchStatusAutoTerm[0]))) {
            setError(`StatusAutoTerm_2`, {
                message: `ステータスボタン デフォルト設定変更1とステータスボタン デフォルト設定変更2の変更時間帯の関係に誤りがあります。`,
                type: 'error'
            });
            errStatusAutoTerm++;
        }

        // Validate type StatusMode with StatusAutoTerm
        /*
        *{value: 2, text: "[出勤][退勤]"}, {value: 4, text: "[出勤][退勤][外出][戻り]"},
        {value: 6, text: "[出勤][退勤][休憩][戻り]"}, {value: 99, text: "手動登録"}
        * */
        // Case checkin/checkout
        if (data.StatusMode == 2) {
            if (data.StatusAutoTerm[0].active === '1' && (data.StatusAutoTerm[0].no == 3 || data.StatusAutoTerm[0].no == 4)) {
                setError(`StatusAutoTerm_0`, {
                    message: `ステータスボタン デフォルト設定変更1の区分にステータスボタンに表示されてない区分が選択されています。`,
                    type: 'error'
                });
                errStatusAutoTerm++;
            }
            if (data.StatusAutoTerm[1].active === '1' && (data.StatusAutoTerm[1].no == 3 || data.StatusAutoTerm[1].no == 4)) {
                setError(`StatusAutoTerm_1`, {
                    message: `ステータスボタン デフォルト設定変更2の区分にステータスボタンに表示されてない区分が選択されています。`,
                    type: 'error'
                });
                errStatusAutoTerm++;
            }
        }
        if (data.StatusMode == 99) {
            const filterListCustomStatus = data.CustomButton.filter(e => e.value != '0');
            if (data.StatusAutoTerm[0].active === '1' && filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[0].no) === undefined) {
                setError(`StatusAutoTerm_0`, {
                    message: `ステータスボタン デフォルト設定変更1の区分にステータスボタンに表示されてない区分が選択されています。`,
                    type: 'error'
                });
                errStatusAutoTerm++;
            }
            if (data.StatusAutoTerm[1].active === '1' && filterListCustomStatus.find(e => e.value == data.StatusAutoTerm[1].no) === undefined) {
                setError(`StatusAutoTerm_1`, {
                    message: `ステータスボタン デフォルト設定変更2の区分にステータスボタンに表示されてない区分が選択されています。`,
                    type: 'error'
                });
                errStatusAutoTerm++;
            }
        }


        const newErrors: any = [];
        if (getValues('StatusMode') == '99') {
            const listCustomButton = data.CustomButton.map((e, index) => {
                e.id = index + 1;
                return e
            })
            if (checkUniqueStatusButton(data.CustomButton).length > 0)
                newErrors.push(...checkUniqueStatusButton(listCustomButton))
            if (checkStatusButtonName(data.CustomButton).length > 0)
                newErrors.push(...checkStatusButtonName(listCustomButton))

            for (let i = 0; i < data.CustomButton.length; i++) {
                if (data.CustomButton[i].value != '0' && data.CustomButton[i].label.trim() == '') {
                    newErrors.push(`ボタン${data.CustomButton[i].id}のカスタムボタン名を入力してください。`)
                }
            }

        } else {
            data.CustomButton = defaultSetting.CustomButton
        }
        if (newErrors.length > 0 || errMealReservationOption > 0 || errStatusAutoTerm > 0
        ) {
            setErrorProvider({
                customButton: newErrors
            });
            return;
        }

        if (getValues("UseRangeIpRestriction") == '1' && getValues('RangeIpRestriction') && validateIpRestriction()) {
            return;
        }

        if (data.UseWorkLocation == '0') {
            data.WorkLocation = ''
        }
        data.WorkLocation = data.UseWorkLocation == '0' ? '' : data.WorkLocation
        data.RangeIpRestriction = data.UseRangeIpRestriction == '0' ? [] : data.RangeIpRestriction
        data.TextRatio = data.UseTextRatio == '0' ? '' : data.TextRatio
        data.CustomButton = data.CustomButton.map(e => {
            e.label = e.label.trim()
            return e
        });
        // Create new Setting
        setIsLoading(true);
        const targetOrg = localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId
        if (data.Id == 0) {
            await createQrCodeSetting(data, targetOrg).then((res: any) => {
                if (!res.success) {
                    toast.error(res.message)
                } else {
                    toast.success('追加しました。')
                    resetFormData(res.detail);
                    setListSettings(res.list);
                    setSelectedSettings(res.detail);
                }
                setIsLoading(false);
            });
        } else {
            await updateQrCodeSetting(data, data.Id, targetOrg).then((res: any) => {
                if (!res.success) {
                    toast.error(res.message)
                } else {
                    toast.success('更新しました。')
                    resetFormData(res.detail);
                    setListSettings(res.list);
                }
                setIsLoading(false);
            })
        }

    }

    const [showModalClone, setShowModalClone] = useState(false);
    const [showModalCfDelete, setShowModalCfDelete] = useState(false);
    const [showModalCfDeleteIp, setShowModalCfDeleteIp] = useState(false);
    const [currentIndexIp, setCurrentIndexIp] = useState(0);
    const selectDevice = (event) => {
        let tempListSetting = [...listSettings];
        tempListSetting = tempListSetting.filter(e => e.Id != 0);
        const selectedDevice = tempListSetting.find(s => s.Id == event.target.value);
        setSelectedSettings(selectedDevice);
        setListSettings(tempListSetting);
        resetFormData(selectedDevice);
    }

    const selectDeviceClone = (event) => {
        if (event.target.value != 0) {
            const selectedDevice = listSettings.find(s => s.Id == event.target.value);
            setSelectedSettingsClone(selectedDevice);
        } else {
            setSelectedSettingsClone(null)
        }
    }

    const cloneBentoList = () => {
        setShowModalClone(true);
    }

    const handleCloseModalClone = () => {
        setShowModalClone(false);
    }

    const createNewSetting = () => {
        const newSetting = listSettings.find(e => e.Id == 0);
        if (newSetting) {
            return;
        }

        const tempListSettings = [...listSettings];
        tempListSettings.push({
            Id: 0,
            ...defaultSetting
        });
        setListSettings(tempListSettings);
        setSelectedSettings({Id: 0, ...defaultSetting});
        reset({Id: 0, ...defaultSetting});

    }
    const handleCloneBento = () => {
        if (selectedSettingsClone) {
            reset({
                ...getValues(),
                MealReservationOption: selectedSettingsClone.MealReservationOption
            });
            setShowModalClone(false);
        }
    }

    const removeSetting = () => {
        if (selectedSettings.Id != 0) {
            const targetOrg = localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId
            deleteQrCodeSetting({action: "delete"}, selectedSettings.Id, targetOrg).then(res => {
                let newListSettings = [...listSettings]
                newListSettings = newListSettings.filter(e => e.Id != selectedSettings.Id);
                if (newListSettings.length > 0) {
                    setSelectedSettings(newListSettings[0]);
                    resetFormData(newListSettings[0]);
                }
                setListSettings(newListSettings);
                setShowModalCfDelete(false);
                toast.success('削除しました。');
            })

        }
    }

    const moveReservationDown = (index) => {
        if (index + 1 < fieldMealReservationOption.length)
            move(index, index + 1)
    }

    const moveReservationUp = (index) => {
        if (index > 0) {
            move(index, index - 1)
        }
    }

    const changeActive = (event, index) => {
        setValue(`MealReservationOption.${index}.active`, event.target.checked);
    }

    const reservationTooltipText = "初期値は、以下で設定されています。\n" +
        "未選択：お弁当を選択してください\n" +
        "選択中：お弁当が選択されています\n" +
        "「手動で変更する」の選択を外すと、初期値に戻ります。";
    return (
        <Fragment>

            <Modal size='lg' className="pt-5 form-password-qr" show={showModalClone} onHide={handleCloseModalClone}>
                <Modal.Header closeButton>
                    他の端末からコピー
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">食事予約の情報を反映します。</p>
                    <p>コピーする端末を選択してください。</p>

                    <Row>
                        <div className="w-25 py-2 item-label">端末ID</div>
                        <div key="inline-radio" className="w-75">
                            <Form.Select className="w-50" value={selectedSettingsClone?.Id}
                                         onChange={selectDeviceClone}>
                                <option value="0">端末を追加してください</option>
                                {
                                    listSettings.length > 0 &&
                                    listSettings.map((e, index) => {
                                        if ((!selectedSettings || (e.Id !== selectedSettings.Id && selectedSettings)) && e.Id != '0' && e.MealReservationOption && e.MealReservationOption.length > 0) {
                                            return <Fragment key={index}>
                                                <option value={e.Id}>{e.DeviceId}</option>
                                            </Fragment>
                                        }
                                    })


                                }
                            </Form.Select>

                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={handleCloseModalClone}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                解除...
                            </Button>
                            :
                            <Button variant="primary" size="sm" type="button"
                                    onClick={handleCloneBento}
                                    className="modal-btn">
                                反映
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
            <Modal size='sm' className="pt-5 form-delete-setting" show={showModalCfDelete}
                   onHide={() => setShowModalCfDelete(false)}>
                <Modal.Header closeButton>
                    <svg fill="#969492" xmlns="http://www.w3.org/2000/svg" className="info-icon"
                         width="15px" height="15px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
                        <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M26,14.1c1.7,0,3,1.3,3,3s-1.3,3-3,3
                    s-3-1.3-3-3S24.3,14.1,26,14.1z M31,35.1c0,0.5-0.4,0.9-1,0.9h-3c-0.4,0-3,0-3,0h-2c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1l0,0
                    c0.5,0,1-0.3,1-0.9v-4c0-0.5-0.4-1.1-1-1.1l0,0c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1h6c0.5,0,1,0.5,1,1.1v8
                    c0,0.5,0.4,0.9,1,0.9l0,0c0.5,0,1,0.5,1,1.1V35.1z"/>
                    </svg>
                    <span className="ms-2">確認</span>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">この端末を削除しますか？</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={() => setShowModalCfDelete(false)}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                削除...
                            </Button>
                            :
                            <Button variant="danger" size="sm" type="button"
                                    onClick={removeSetting}
                                    className="modal-btn">
                                削除
                            </Button>
                    }

                </Modal.Footer>
            </Modal>

            <Modal size='sm' className="pt-5 form-delete-setting" show={showModalCfDeleteIp}
                   onHide={() => setShowModalCfDeleteIp(false)}>
                <Modal.Header closeButton>
                    <svg fill="#969492" xmlns="http://www.w3.org/2000/svg" className="info-icon"
                         width="15px" height="15px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52">
                        <path d="M26,2C12.7,2,2,12.7,2,26s10.7,24,24,24s24-10.7,24-24S39.3,2,26,2z M26,14.1c1.7,0,3,1.3,3,3s-1.3,3-3,3
                    s-3-1.3-3-3S24.3,14.1,26,14.1z M31,35.1c0,0.5-0.4,0.9-1,0.9h-3c-0.4,0-3,0-3,0h-2c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1l0,0
                    c0.5,0,1-0.3,1-0.9v-4c0-0.5-0.4-1.1-1-1.1l0,0c-0.5,0-1-0.3-1-0.9v-2c0-0.5,0.4-1.1,1-1.1h6c0.5,0,1,0.5,1,1.1v8
                    c0,0.5,0.4,0.9,1,0.9l0,0c0.5,0,1,0.5,1,1.1V35.1z"/>
                    </svg>
                    <span className="ms-2">確認</span>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">この設定を削除しますか？</p>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={() => setShowModalCfDeleteIp(false)}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                削除...
                            </Button>
                            :
                            <Button variant="danger" size="sm" type="button"
                                    onClick={() => {
                                        removeRangeIpRestriction(currentIndexIp);
                                        setCurrentIndexIp(0);
                                        setShowModalCfDeleteIp(false);
                                    }}
                                    className="modal-btn">
                                削除
                            </Button>
                    }

                </Modal.Footer>
            </Modal>


            {
                orgInfo &&
                <div className="provider-settings qr-settings">
                    <Card className="mt-3 card-container">
                        <Card.Body className="page-title">{pageTitle}</Card.Body>
                    </Card>


                    <Card className="mt-3 card-container">

                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex align-items-center">
                                {
                                    orgInfo && <QrPasswordSetting/>
                                }
                            </ListGroup.Item>
                        </ListGroup>

                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex align-items-center">
                                <div className="w-25 py-2 item-label">ロゴの設定</div>
                                <div key="inline-radio" className="w-50">
                                    <div className='w-100'>
                                        {
                                            orgInfo && <QrLogoSetting/>
                                        }
                                    </div>
                                </div>
                            </ListGroup.Item>

                        </ListGroup>

                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex align-items-center">
                                <div className="w-25 py-2 item-label">QRコード</div>
                                <div key="inline-radio" className="w-50">
                                    <div className='w-100'>
                                        {
                                            orgInfo && <QrCodeReader/>
                                        }
                                    </div>
                                </div>
                            </ListGroup.Item>

                        </ListGroup>


                        <Card className="mt-3 card-container m-3">
                            <ListGroup variant="flush" className="list-device">
                                <ListGroup.Item className="d-flex align-items-center w-50 list-device-form">
                                    <Form.Select className="w-50" value={selectedSettings?.Id} onChange={selectDevice}>

                                        {
                                            listSettings.length > 0 ?
                                                listSettings.map((e, index) => {
                                                    return <Fragment key={index}>
                                                        <option value={e.Id}>{e.DeviceId}</option>
                                                    </Fragment>
                                                }) :
                                                <option value="0">端末を追加してください</option>

                                        }
                                    </Form.Select>
                                    <button type={'button'} onClick={createNewSetting}
                                            className={'btn btn-screen-password ms-3 d-flex align-items-center'}>
                                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.90915 6.54288H9.65933C9.74924 6.54145 9.83506 6.5051 9.89864 6.44152C9.96222 6.37794 9.99857 6.29211 10 6.20221V5.52017C9.99857 5.43026 9.96222 5.34444 9.89864 5.28085C9.83506 5.21727 9.74924 5.18092 9.65933 5.17949H5.90915C5.87886 5.18131 5.84852 5.17668 5.82014 5.16591C5.79176 5.15514 5.76599 5.13847 5.74452 5.11701C5.72306 5.09555 5.70639 5.06977 5.69562 5.0414C5.68485 5.01302 5.68022 4.98268 5.68204 4.95238V1.20151C5.68061 1.11161 5.64426 1.02578 5.58068 0.9622C5.5171 0.898618 5.43127 0.862267 5.34137 0.86084H4.65933C4.56942 0.862267 4.4836 0.898618 4.42001 0.9622C4.35643 1.02578 4.32008 1.11161 4.31865 1.20151V4.95169C4.32047 4.98199 4.31584 5.01233 4.30507 5.0407C4.2943 5.06908 4.27763 5.09485 4.25617 5.11632C4.23471 5.13778 4.20893 5.15445 4.18056 5.16522C4.15218 5.17599 4.12184 5.18062 4.09154 5.1788H0.340673C0.250648 5.18023 0.164722 5.21668 0.101122 5.28041C0.0375211 5.34414 0.00124912 5.43014 0 5.52017V6.20221C0.00142736 6.29211 0.037778 6.37794 0.10136 6.44152C0.164942 6.5051 0.250766 6.54145 0.340673 6.54288H4.09085C4.12115 6.54106 4.15149 6.54569 4.17986 6.55646C4.20824 6.56723 4.23401 6.5839 4.25548 6.60536C4.27694 6.62683 4.29361 6.6526 4.30438 6.68098C4.31515 6.70935 4.31978 6.7397 4.31796 6.76999V10.5202C4.31939 10.6101 4.35574 10.6959 4.41932 10.7595C4.4829 10.8231 4.56873 10.8594 4.65863 10.8608H5.34067C5.43058 10.8594 5.51641 10.8231 5.57999 10.7595C5.64357 10.6959 5.67992 10.6101 5.68135 10.5202V6.76999C5.67952 6.73963 5.68417 6.70923 5.69498 6.6808C5.70579 6.65237 5.72252 6.62656 5.74406 6.60509C5.7656 6.58362 5.79146 6.56696 5.81992 6.55624C5.84838 6.54551 5.8788 6.54096 5.90915 6.54288Z"
                                                fill="#0070D2"/>
                                        </svg>
                                        <span className="ms-2">端末を追加</span>
                                    </button>
                                </ListGroup.Item>
                            </ListGroup>
                            {
                                listSettings.length > 0 &&
                                <Form onSubmit={handleSubmit(submitFormData)}>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="d-flex align-items-center">
                                            <div className="w-25 py-2 item-label">端末</div>
                                            <div key="inline-radio" className="w-50">
                                                <div className='w-50 d-flex align-items-center'>
                                                    <label className="w-25 fs-13">端末ID</label>
                                                    <Form.Control
                                                        className={`w-75 modal-form-control ${errors[`DeviceId`] ? 'error' : ''}`}
                                                        size="sm"
                                                        {...register(`DeviceId`, {
                                                            required: true,
                                                        })}
                                                    />

                                                    <Form.Control
                                                        className={`w-25 ms-3 modal-form-control d-none`}
                                                        size="sm"
                                                        {...register(`Id`)}
                                                    />


                                                </div>
                                                {
                                                    isSubmitted &&
                                                    <div key="inline-radio" className="w-50 d-flex">
                                                        <label className="w-25 fs-13"></label>
                                                        <div className='w-75 d-flex align-items-center'>


                                                            <ErrorMessage
                                                                name={`DeviceId`}
                                                                className={"px-0 pt-1 mb-0"}
                                                                errors={errors}/>


                                                        </div>
                                                    </div>
                                                }
                                                <div className='w-50 d-flex align-items-center mt-3'>
                                                    <label className="w-25 fs-13">端末説明</label>

                                                    <div className='w-75 align-items-center'>
                                                        <Form.Control
                                                            className={`w-100 modal-form-control ${errors[`DeviceNote`] ? 'error' : ''}`}
                                                            size="sm"
                                                            {...register(`DeviceNote`)}
                                                        />
                                                    </div>

                                                </div>
                                                {
                                                    isSubmitted &&
                                                    <div className='w-50 d-flex align-items-center'>
                                                        <label className="w-25 fs-13"></label>
                                                        <div className='w-75 align-items-center'>

                                                            <ErrorMessage
                                                                name={`DeviceNote`}
                                                                className={"px-0 pt-1 mb-0"}
                                                                errors={errors}/>


                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                        </ListGroup.Item>


                                        <ListGroup.Item className="d-flex align-items-center">
                                            <div className="w-25 py-2 item-label">時刻表示</div>
                                            <div key="inline-radio" className="w-50">
                                                <Form.Check
                                                    inline
                                                    label="24時間表示"
                                                    value={1}
                                                    type="radio"
                                                    {...register('ClockMode')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                                <Form.Check
                                                    inline
                                                    value={0}
                                                    label="12時間表示（AM/PM）"
                                                    {...register('ClockMode')}
                                                    type="radio"
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                            </div>
                                        </ListGroup.Item>


                                        <ListGroup.Item>
                                            {/*<div className="d-flex align-items-center mb-1 mt-1">*/}
                                            {/*    <div className="w-25 py-2 item-label"></div>*/}
                                            {/*    <div key="inline-radio" className="w-50">*/}

                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className={`row d-flex mt-2`}>
                                                <div className="w-25 py-2 item-label">ステータスボタン</div>
                                                <div className="w-75">

                                                    <div className="w-50">
                                                        <Form.Select {...register('StatusMode')}>
                                                            {
                                                                selectBoxRenderValue.map((e, index) => {
                                                                    return <Fragment key={index}>
                                                                        <option value={e.value}>{e.text}</option>
                                                                    </Fragment>
                                                                })

                                                            }
                                                        </Form.Select>
                                                    </div>
                                                    <div className='status-mode w-100'>


                                                        {getValues('StatusMode') == '99' && fieldCustomButton.map((item, index) => (
                                                            <div key={index}
                                                                 className="d-flex status-mode-title w-100 mt-2">
                                                                <div className="me-2 fs-13 ">ボタン{index + 1} <span
                                                                    className="mx-3">区分</span></div>
                                                                <Form.Select
                                                                    className="w-25" {...register(`CustomButton.${index}.value`)}
                                                                >
                                                                    {
                                                                        index > 1 ?
                                                                            selectBoxStatusCodeButton.map((e, index1) => {

                                                                                return <Fragment key={index1}>
                                                                                    <option
                                                                                        value={e.value}>{e.text}</option>
                                                                                </Fragment>


                                                                            }) :
                                                                            selectBoxStatusCodeButton.map((e, index1) => {
                                                                                if (index1 > 0)
                                                                                    return <Fragment key={index1}>
                                                                                        <option
                                                                                            value={e.value}>{e.text}</option>
                                                                                    </Fragment>


                                                                            })
                                                                    }
                                                                </Form.Select>
                                                                <div className="py-2 mx-3 fs-13">ボタン名</div>

                                                                <Form.Control className="w-50 h-auto" size="sm"
                                                                              as="textarea" rows={3}
                                                                              maxLength={32}
                                                                              disabled={getValues(`CustomButton.${index}.value`) == '0'}
                                                                              {...register(`CustomButton.${index}.label`)}></Form.Control>

                                                            </div>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSubmitted && errorProvider.customButton?.length > 0 &&
                                                <div className="mb-2">
                                                    {
                                                        errorProvider.customButton.map(e => {
                                                            return <p key={e} className="w-100 error mb-0">{e}</p>
                                                        })
                                                    }

                                                </div>
                                            }

                                        </ListGroup.Item>

                                        {watchStatusAutoTerm && fieldStatusAutoTerm.map((item, index) => (
                                            <ListGroup.Item key={index}>
                                                <div className="d-flex align-items-center mb-1 mt-1">
                                                    <div className="w-25 py-2 item-label"
                                                         style={{wordBreak: 'keep-all'}}>ステータスボタン
                                                        デフォルト設定変更{index + 1}</div>
                                                    <div className="w-75">
                                                        <Row className="d-flex">
                                                            <Col xs={12} md={12} lg={12} xxl={6}>
                                                                <Form.Check
                                                                    inline
                                                                    label="設定しない"
                                                                    {...register(`StatusAutoTerm.${index}.active`)}
                                                                    type="radio"
                                                                    value={0}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="設定する"
                                                                    {...register(`StatusAutoTerm.${index}.active`)}
                                                                    type="radio"
                                                                    value={1}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                                <span className="checkbox-label mb-0 pe-3">区分</span>

                                                                {
                                                                    getValues('StatusMode') == '2' &&
                                                                    <Form.Select
                                                                        className="auto-term-select-box d-inline-block"
                                                                        disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                        {...register(`StatusAutoTerm.${index}.no`)}>
                                                                        {
                                                                            selectBoxStatusCodeButtonCase2.map((e, key) => {
                                                                                return <Fragment key={key}>
                                                                                    <option
                                                                                        value={e.value}>{e.text}</option>
                                                                                </Fragment>
                                                                            })

                                                                        }
                                                                    </Form.Select>
                                                                }
                                                                {
                                                                    getValues('StatusMode') == '4' &&
                                                                    <Form.Select
                                                                        className="auto-term-select-box d-inline-block"
                                                                        disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                        {...register(`StatusAutoTerm.${index}.no`)}>
                                                                        {
                                                                            selectBoxStatusCodeButtonCase4.map((e, key) => {
                                                                                return <Fragment key={key}>
                                                                                    <option
                                                                                        value={e.value}>{e.text}</option>
                                                                                </Fragment>
                                                                            })

                                                                        }
                                                                    </Form.Select>
                                                                }
                                                                {
                                                                    getValues('StatusMode') == '6' &&
                                                                    <Form.Select
                                                                        className="auto-term-select-box d-inline-block"
                                                                        disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                        {...register(`StatusAutoTerm.${index}.no`)}>
                                                                        {
                                                                            selectBoxStatusCodeButtonCase6.map((e, key) => {
                                                                                return <Fragment key={key}>
                                                                                    <option
                                                                                        value={e.value}>{e.text}</option>
                                                                                </Fragment>
                                                                            })

                                                                        }
                                                                    </Form.Select>
                                                                }
                                                                {
                                                                    getValues('StatusMode') == '99' &&
                                                                    <Form.Select
                                                                        className="auto-term-select-box d-inline-block"
                                                                        disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                        {...register(`StatusAutoTerm.${index}.no`)}>
                                                                        {
                                                                            selectBoxStatusCodeButtonCase99.map((e, key) => {
                                                                                return <Fragment key={key}>
                                                                                    <option
                                                                                        value={e.value}>{e.text}</option>
                                                                                </Fragment>
                                                                            })

                                                                        }
                                                                    </Form.Select>
                                                                }
                                                            </Col>
                                                            <Col xs={12} md={12} lg={12} xxl={6}
                                                                 className="d-flex mt-2 mt-xxl-0">
                                                                <div className="py-2 mx-3 fs-13"
                                                                     style={{wordBreak: 'keep-all'}}>変更時間帯
                                                                </div>


                                                                <div className="w-100 d-flex">

                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                            type="time" {...register(`StatusAutoTerm.${index}.startTime`)}
                                                                            className="ml-3 mr-5 input-time"/>


                                                                    </Form.Group>

                                                                    <div className="mx-3">〜</div>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            disabled={getValues(`StatusAutoTerm.${index}.active`) == '0'}
                                                                            type="time" {...register(`StatusAutoTerm.${index}.endTime`)}
                                                                            className="input-time"/>

                                                                    </Form.Group>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                {
                                                    isSubmitted &&
                                                    <ErrorMessage
                                                        name={`StatusAutoTerm_${index}`}
                                                        className={`px-0 pt-1 ${index == 1 ? '' : 'mb-0'}`}
                                                        errors={errors}/>

                                                }
                                                {
                                                    isSubmitted && index == 1 &&
                                                    <ErrorMessage
                                                        name={`StatusAutoTerm_2`}
                                                        className={"px-0 pt-1 mb-0"}
                                                        errors={errors}/>

                                                }
                                            </ListGroup.Item>
                                        ))}

                                        <ListGroup.Item>
                                            <div className="d-flex align-items-center mb-1 mt-1">
                                                <div className="w-25 py-2 item-label">表示切り替え</div>
                                                <div className="w-75">
                                                    <Row className="d-flex align-items-center meal-settings">
                                                        <Col xs={12} md={12} lg={12} xxl={6}>
                                                            <div className="d-flex align-items-center">
                                                                <Form.Check
                                                                    inline
                                                                    label="食事予約"
                                                                    {...register(`MealReservation`)}
                                                                    type="radio"
                                                                    value={'1'}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="お知らせ"
                                                                    {...register(`MealReservation`)}
                                                                    type="radio"
                                                                    value={'2'}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="非表示"
                                                                    {...register(`MealReservation`)}
                                                                    type="radio"
                                                                    value={'0'}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                    <Row className="d-flex align-items-center">
                                                        {
                                                            getValues('MealReservation') == 2 && <Fragment>
                                                                <div className="d-flex align-items-center mt-3">
                                                                    <label
                                                                        className="fs-13 me-3"
                                                                        htmlFor={`reservation-notice-title`}>タイトル</label>

                                                                    <Form.Control className="w-60 h-auto" size="sm"
                                                                                  type={'text'}
                                                                                  {...register(`MealReservationNotice.title`)}
                                                                    ></Form.Control>

                                                                </div>
                                                                <div className="d-flex align-items-center mt-3">
                                                                    <label
                                                                        className="fs-13 me-3"
                                                                        htmlFor={`reservation-notice-desc`}>お知らせの内容</label>

                                                                    <Form.Control className="w-60 h-auto" size="sm"
                                                                                  as="textarea" rows={3}
                                                                                  {...register(`MealReservationNotice.content`)}
                                                                    ></Form.Control>

                                                                </div>

                                                            </Fragment>

                                                        }
                                                        {
                                                            getValues('MealReservation') == 1 &&
                                                            <Fragment>
                                                                <div className="d-flex mt-4 align-items-center">

                                                                    <div className="py-2 ms-0 m fs-13"
                                                                         style={{wordBreak: 'keep-all'}}>注文可能時間帯
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                type="time" {...register(`MealReservationTime.startTime`)}
                                                                                className="ms-3 mr-5 input-time"/>
                                                                        </Form.Group>
                                                                        <div className="mx-3">〜</div>
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                type="time" {...register(`MealReservationTime.endTime`)}
                                                                                className="input-time"/>
                                                                        </Form.Group>
                                                                    </div>


                                                                </div>

                                                                <div className="d-flex mt-1 align-items-center">
                                                                    <ErrorMessage
                                                                        name={`MealReservationTime.startTime`}
                                                                        className={"px-0 pt-1 mb-0"}
                                                                        errors={errors}/>
                                                                </div>

                                                                <div className="d-flex mt-4 align-items-center">

                                                                    <div
                                                                        className="py-2 ms-0 m fs-13 d-flex align-items-center w-label-reservation"
                                                                        style={{wordBreak: 'keep-all'}}>タイトル
                                                                        <Tooltip text={reservationTooltipText} left
                                                                                 width="360px"/>

                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <div
                                                                            className="d-flex align-items-center ms-4">

                                                                            <Form.Check className="mb-0">
                                                                                <Form.Check.Input
                                                                                    type="checkbox"
                                                                                    id={`checkbox-reservation-title`}
                                                                                    checked={watchMealReservationTitle}
                                                                                    onChange={(event) => {
                                                                                        setValue('MealReservationTitle.active', event.target.checked)
                                                                                    }}

                                                                                />

                                                                            </Form.Check>
                                                                            <label
                                                                                className="ms-2 fs-13"
                                                                                htmlFor={`checkbox-reservation-title`}>手動で変更する</label>

                                                                        </div>

                                                                    </div>


                                                                </div>
                                                                <div className="d-flex mt-1 align-items-center">
                                                                    <div className="w-label-reservation"></div>
                                                                    <p className="fs-13 w-label-reservation-description">お弁当名のボタンの未選択時、選択時のそれぞれのタイトル名の変更を行うことができます。</p>
                                                                </div>
                                                                <div
                                                                    className="d-flex mt-1 align-items-center form-reservation-array">
                                                                    <div className="w-label-reservation"></div>
                                                                    <div className="form-title-reservation">
                                                                        <div className="d-flex mt-1 align-items-center">

                                                                            <div className="py-2 ms-3 m fs-13"
                                                                                 style={{wordBreak: 'keep-all'}}>未選択
                                                                            </div>
                                                                            <div className="w-100 d-flex">
                                                                                <Form.Group className="w-100">
                                                                                    <Form.Control
                                                                                        disabled={watchMealReservationTitle != true}
                                                                                        type="text" {...register(`MealReservationTitle.notSelectedMeal`)}
                                                                                        className="ms-3 mr-5"/>
                                                                                </Form.Group>

                                                                            </div>


                                                                        </div>
                                                                        <div className="d-flex  align-items-center">
                                                                            <div className="py-2 ms-3 m fs-13"
                                                                                 style={{
                                                                                     wordBreak: 'keep-all',
                                                                                     width: "40px"
                                                                                 }}>
                                                                            </div>
                                                                            <div className="w-100 d-flex">

                                                                                <ErrorMessage
                                                                                    name={`MealReservationTitle.notSelectedMeal`}
                                                                                    className={"px-0 mb-0 ms-4 pt-0"}
                                                                                    errors={errors}/>
                                                                            </div>

                                                                        </div>

                                                                        <div className="d-flex mt-1 align-items-center">

                                                                            <div className="py-2 ms-3 fs-13"
                                                                                 style={{wordBreak: 'keep-all'}}>選択中
                                                                            </div>
                                                                            <div className="w-100 d-flex">
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        disabled={watchMealReservationTitle != true}
                                                                                        type="text" {...register(`MealReservationTitle.selectedMeal`)}
                                                                                        className="ms-3 mr-5"/>
                                                                                </Form.Group>

                                                                            </div>


                                                                        </div>
                                                                        <div className="d-flex align-items-center">

                                                                            <div className="py-2 ms-3 fs-13"
                                                                                 style={{
                                                                                     wordBreak: 'keep-all',
                                                                                     width: "40px"
                                                                                 }}>
                                                                            </div>
                                                                            <div className="w-100 d-flex">

                                                                                <ErrorMessage
                                                                                    name={`MealReservationTitle.selectedMeal`}
                                                                                    className={"px-0 mb-0 ms-4 pt-0"}
                                                                                    errors={errors}/>
                                                                            </div>


                                                                        </div>


                                                                    </div>

                                                                </div>
                                                                <div className="mt-2">

                                                                    <div className="form-title-reservation">
                                                                        {
                                                                            getValues('MealReservation') == 1 &&
                                                                            <p
                                                                                onClick={cloneBentoList}
                                                                                className="add-meal-reservation mt-0 mb-0">他の端末からコピー</p>
                                                                        }
                                                                    </div>

                                                                </div>


                                                            </Fragment>

                                                        }
                                                        {getValues('MealReservation') == 1 && fieldMealReservationOption.length > 0 && fieldMealReservationOption.map((field, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className={`form-reservation-array pb-3 pt-2`}>
                                                                        <div className="d-flex">
                                                                            <div
                                                                                className="form-meal d-flex align-items-center mt-3">
                                                                                <p className="me-3 name-meal-reservations">
                                                                                    <span
                                                                                        className="name-meal-reservations-index">{index + 1}</span>
                                                                                    <span
                                                                                        className="ms-1 name-meal-reservations-title">お弁当名</span>
                                                                                </p>

                                                                                <Form.Control
                                                                                    className={(isSubmitted && !getValues(`MealReservationOption.${index}.name`) &&
                                                                                        errors[`MealReservationOption_${index}_name`]) ?
                                                                                        `w-75 modal-form-control error` : 'w-75 modal-form-control'}
                                                                                    size="sm"
                                                                                    disabled={getValues(`MealReservationOption.${index}.active`) == true}
                                                                                    {...register(`MealReservationOption.${index}.name` as const)}
                                                                                />

                                                                                <span className="mx-3">備考</span>
                                                                                <Form.Control
                                                                                    className="w-75 modal-form-control"
                                                                                    size="sm"
                                                                                    disabled={getValues(`MealReservationOption.${index}.active`) == true}
                                                                                    {...register(`MealReservationOption.${index}.remark` as const)}
                                                                                />

                                                                            </div>
                                                                            {
                                                                                fieldMealReservationOption.length > 1 &&
                                                                                <div
                                                                                    className="w-25 position-relative d-flex align-items-center mt-3">
                                                                                    <ButtonGroup
                                                                                        className="btn-group-sort mx-2">
                                                                                        <Button
                                                                                            className={`d-flex btn-sort${index == 0 ? ' btn-sort-disabled' : ''}`}

                                                                                            onClick={() => moveReservationUp(index)}
                                                                                        >
                                                                                            <svg width="16" height="17"
                                                                                                 viewBox="0 0 16 17"
                                                                                                 fill="none"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fillRule="evenodd"
                                                                                                      clipRule="evenodd"
                                                                                                      d="M12.7383 6.93153C12.9845 6.68537 12.9845 6.34691 12.7383 6.10076L8.12296 1.57768C7.87681 1.33153 7.50758 1.33153 7.26143 1.57768L2.64604 6.10076C2.39989 6.34691 2.39989 6.68537 2.64604 6.93153L3.50758 7.7623C3.75373 8.00845 4.12296 8.00845 4.36912 7.7623L5.81527 6.34691C6.06143 6.10076 6.49219 6.28537 6.49219 6.62383V14.9315C6.49219 15.2392 6.76912 15.5469 7.10758 15.5469H8.33835C8.67681 15.5469 8.95373 15.2084 8.95373 14.9315V6.62383C8.95373 6.2546 9.3845 6.10076 9.63066 6.34691L11.0768 7.7623C11.323 8.00845 11.6922 8.00845 11.9383 7.7623L12.7383 6.93153Z"
                                                                                                      fill="#747474"/>
                                                                                            </svg>
                                                                                        </Button>{' '}
                                                                                        <Button
                                                                                            className={
                                                                                                `d-flex btn-sort btn-sort-down${index == fieldMealReservationOption.length - 1 ? ' btn-sort-disabled' : ''}`
                                                                                            }
                                                                                            onClick={() => moveReservationDown(index)}
                                                                                        >
                                                                                            <svg width="16" height="17"
                                                                                                 viewBox="0 0 16 17"
                                                                                                 fill="none"
                                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fillRule="evenodd"
                                                                                                      clipRule="evenodd"
                                                                                                      d="M12.7383 6.93153C12.9845 6.68537 12.9845 6.34691 12.7383 6.10076L8.12296 1.57768C7.87681 1.33153 7.50758 1.33153 7.26143 1.57768L2.64604 6.10076C2.39989 6.34691 2.39989 6.68537 2.64604 6.93153L3.50758 7.7623C3.75373 8.00845 4.12296 8.00845 4.36912 7.7623L5.81527 6.34691C6.06143 6.10076 6.49219 6.28537 6.49219 6.62383V14.9315C6.49219 15.2392 6.76912 15.5469 7.10758 15.5469H8.33835C8.67681 15.5469 8.95373 15.2084 8.95373 14.9315V6.62383C8.95373 6.2546 9.3845 6.10076 9.63066 6.34691L11.0768 7.7623C11.323 8.00845 11.6922 8.00845 11.9383 7.7623L12.7383 6.93153Z"
                                                                                                      fill="#747474"/>
                                                                                            </svg>
                                                                                        </Button>

                                                                                    </ButtonGroup>
                                                                                </div>
                                                                            }

                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <div
                                                                                className="form-meal d-flex align-items-center">
                                                                                <p className="me-3 name-meal-reservations"></p>

                                                                                {
                                                                                    isSubmitted && watchMealReservationOption &&
                                                                                    <div className="flex">
                                                                                        <div
                                                                                            className="form-meal d-flex align-items-center">
                                                                                            <div
                                                                                                className="d-flex align-items-center">
                                                                                                <ErrorMessage
                                                                                                    name={`MealReservationOption_${index}_name`}
                                                                                                    className={"px-0 pt-1 mb-0"}
                                                                                                    errors={errors}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                            </div>

                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <div
                                                                                className="form-meal d-flex align-items-center mt-3">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <p className="me-3 name-meal-reservations">
                                                                                        <span
                                                                                            className="name-meal-reservations-index"></span>
                                                                                        <span
                                                                                            className="ms-1 name-meal-reservations-title">送信コード</span>
                                                                                    </p>
                                                                                    <Form.Control
                                                                                        className={(isSubmitted && !getValues(`MealReservationOption.${index}.active`) &&
                                                                                            errors[`MealReservationOption_${index}_code`]) ?
                                                                                            `w-75 modal-form-control error` : 'w-75 modal-form-control'}
                                                                                        size="sm"
                                                                                        disabled={getValues(`MealReservationOption.${index}.active`) == true}
                                                                                        {...register(`MealReservationOption.${index}.code` as const)}
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="d-flex align-items-center ms-3">

                                                                                    <Form.Check className="mb-0">
                                                                                        <Form.Check.Input
                                                                                            type="checkbox"
                                                                                            id={`form-reservation-index-${index}`}
                                                                                            checked={getValues(`MealReservationOption.${index}.active`)}
                                                                                            onChange={(event) => changeActive(event, index)}

                                                                                        />

                                                                                    </Form.Check>
                                                                                    <label
                                                                                        className="ms-2"
                                                                                        htmlFor={`form-reservation-index-${index}`}>非表示にする</label>

                                                                                </div>

                                                                                <div
                                                                                    className="d-flex align-items-center ms-3">
                                                                                    <a className="btn-remove-setting position-relative mx-3"
                                                                                       onClick={() =>
                                                                                           remove(index)
                                                                                       }>
                                                                                        この項目を削除する
                                                                                    </a>


                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {
                                                                            isSubmitted && watchMealReservationOption &&
                                                                            <div className="flex">
                                                                                <div
                                                                                    className="form-meal d-flex align-items-center">
                                                                                    <div
                                                                                        className="d-flex align-items-center text-error-meal-reservations">
                                                                                        <ErrorMessage
                                                                                            name={`MealReservationOption_${index}_code`}
                                                                                            className={"px-0 pt-1 mb-0"}
                                                                                            errors={errors}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>

                                                                </Fragment>
                                                            )
                                                        })}
                                                        {
                                                            getValues('MealReservation') == 1 &&
                                                            <div className="d-inline-block mt-4">

                                                                <button type={'button'}
                                                                        onClick={() => append({
                                                                            name: '',
                                                                            active: false,
                                                                            remark: '',
                                                                            code: ''
                                                                        })}
                                                                        className={'btn btn-screen-password d-flex align-items-center'}>
                                                                    <svg width="10" height="11" viewBox="0 0 10 11"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M5.90915 6.54288H9.65933C9.74924 6.54145 9.83506 6.5051 9.89864 6.44152C9.96222 6.37794 9.99857 6.29211 10 6.20221V5.52017C9.99857 5.43026 9.96222 5.34444 9.89864 5.28085C9.83506 5.21727 9.74924 5.18092 9.65933 5.17949H5.90915C5.87886 5.18131 5.84852 5.17668 5.82014 5.16591C5.79176 5.15514 5.76599 5.13847 5.74452 5.11701C5.72306 5.09555 5.70639 5.06977 5.69562 5.0414C5.68485 5.01302 5.68022 4.98268 5.68204 4.95238V1.20151C5.68061 1.11161 5.64426 1.02578 5.58068 0.9622C5.5171 0.898618 5.43127 0.862267 5.34137 0.86084H4.65933C4.56942 0.862267 4.4836 0.898618 4.42001 0.9622C4.35643 1.02578 4.32008 1.11161 4.31865 1.20151V4.95169C4.32047 4.98199 4.31584 5.01233 4.30507 5.0407C4.2943 5.06908 4.27763 5.09485 4.25617 5.11632C4.23471 5.13778 4.20893 5.15445 4.18056 5.16522C4.15218 5.17599 4.12184 5.18062 4.09154 5.1788H0.340673C0.250648 5.18023 0.164722 5.21668 0.101122 5.28041C0.0375211 5.34414 0.00124912 5.43014 0 5.52017V6.20221C0.00142736 6.29211 0.037778 6.37794 0.10136 6.44152C0.164942 6.5051 0.250766 6.54145 0.340673 6.54288H4.09085C4.12115 6.54106 4.15149 6.54569 4.17986 6.55646C4.20824 6.56723 4.23401 6.5839 4.25548 6.60536C4.27694 6.62683 4.29361 6.6526 4.30438 6.68098C4.31515 6.70935 4.31978 6.7397 4.31796 6.76999V10.5202C4.31939 10.6101 4.35574 10.6959 4.41932 10.7595C4.4829 10.8231 4.56873 10.8594 4.65863 10.8608H5.34067C5.43058 10.8594 5.51641 10.8231 5.57999 10.7595C5.64357 10.6959 5.67992 10.6101 5.68135 10.5202V6.76999C5.67952 6.73963 5.68417 6.70923 5.69498 6.6808C5.70579 6.65237 5.72252 6.62656 5.74406 6.60509C5.7656 6.58362 5.79146 6.56696 5.81992 6.55624C5.84838 6.54551 5.8788 6.54096 5.90915 6.54288Z"
                                                                            fill="#0070D2"/>
                                                                    </svg>
                                                                    <span className="ms-2">追加</span>
                                                                </button>


                                                            </div>

                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                            {
                                                getValues('MealReservation') == 2 && <Fragment>
                                                    <div className="d-flex align-items-center mb-1 mt-1">
                                                        <div className="w-25 py-2">
                                                            <ErrorMessage
                                                                name={`MealReservationNotice.title`}
                                                                className={"px-0 pt-1 mb-0"}
                                                                errors={errors}/>
                                                            <ErrorMessage
                                                                name={`MealReservationNotice.content`}
                                                                className={"px-0 pt-1 mb-0"}
                                                                errors={errors}/>
                                                        </div>

                                                    </div>
                                                </Fragment>
                                            }

                                        </ListGroup.Item>

                                        <ListGroup.Item className="w-100 d-flex align-items-center">

                                            <div className="w-25 py-2 item-label">打刻時音声モード</div>
                                            <div className="w-75">
                                                <Form.Check
                                                    inline
                                                    label="音声なし"
                                                    value={0}
                                                    type="radio"
                                                    {...register('SoundMode')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="音声あり（音）"
                                                    type="radio"
                                                    value={1}
                                                    {...register('SoundMode')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                                <Form.Check
                                                    inline
                                                    label={<span>音声あり（メッセージ）</span>}
                                                    type="radio"
                                                    value={2}
                                                    {...register('SoundMode')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                            </div>

                                        </ListGroup.Item>
                                        {
                                            connectType == CONNECT_TYPE_V5 &&
                                            <ListGroup.Item className="w-100 d-flex align-items-center">

                                                <div className="w-25 py-2 item-label">勤務場所設定</div>
                                                <div className="w-50 d-flex align-items-center">
                                                    <Form.Check
                                                        inline
                                                        label="設定しない"
                                                        value={'0'}
                                                        type="radio"
                                                        {...register('UseWorkLocation')}
                                                        className="checkbox-label mb-0 radio-form-check"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="設定する"
                                                        type="radio"
                                                        value={'1'}
                                                        {...register('UseWorkLocation')}
                                                        className="checkbox-label mb-0 radio-form-check"
                                                    />


                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="w-25">
                                                            <p className="fs-13 mb-0 mt-0">勤務場所コード</p>
                                                        </div>
                                                        <div className="d-flex align-items-center w-75">
                                                            <div className="row">
                                                                <Form.Control
                                                                    className={`w-work-location-code w-100 ms-3 ${errors[`WorkLocation`] ? 'error' : ''}`}
                                                                    size="sm"
                                                                    disabled={getValues('UseWorkLocation') == 0}
                                                                    {...register(`WorkLocation`)}
                                                                />
                                                                {
                                                                    isSubmitted && errors[`WorkLocation`] &&
                                                                    <ErrorMessage
                                                                        name={`WorkLocation`}
                                                                        className={"px-0 pt-1 mb-0"}
                                                                        errors={errors}/>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ListGroup.Item>
                                        }
                                        {/*<ListGroup.Item className="w-100 d-flex align-items-center">

                                            <div className="w-25 py-2 item-label">文字のサイズ</div>
                                            <div className="w-50 d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    label="デフォルト値"
                                                    value={'0'}
                                                    type="radio"
                                                    {...register('UseTextRatio')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="変更する"
                                                    type="radio"
                                                    value={'1'}
                                                    {...register('UseTextRatio')}
                                                    className="checkbox-label mb-0 radio-form-check"
                                                />

                                                <p className="fs-13 mb-0 mt-0">デフォルト値の倍率</p>
                                                <Form.Control className="work-location-code w-25 ms-3"
                                                              type="number"
                                                              disabled={getValues('UseTextRatio') == 0}
                                                              {...register(`TextRatio`)}
                                                />
                                            </div>

                                        </ListGroup.Item>*/}

                                        <ListGroup.Item>
                                            <div className="d-flex align-items-center mb-1 mt-1">
                                                <div className="w-25 py-2 item-label">IP制限</div>
                                                <div className="w-75">
                                                    <Row className="d-flex">
                                                        <Col xs={12} md={12} lg={12} xxl={3}>
                                                            <div className="d-flex list-item-ip-restrict">
                                                                <Form.Check
                                                                    inline
                                                                    label="設定しない"
                                                                    {...register(`UseRangeIpRestriction`)}
                                                                    type="radio"
                                                                    value={'0'}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="設定する"
                                                                    {...register(`UseRangeIpRestriction`)}
                                                                    type="radio"
                                                                    value={'1'}
                                                                    className="checkbox-label mb-0 radio-form-check"
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={12} lg={12} xxl={6}>
                                                            {getValues('UseRangeIpRestriction') == 1 && fieldRangeIpRestriction.length > 0 && fieldRangeIpRestriction.map((field, index) => {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <div
                                                                            className={`mb-3 mt-2`}>
                                                                            <div className="d-flex align-items-center">
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="text" {...register(`RangeIpRestriction.${index}.startIp`)}
                                                                                        className="ml-3 mr-5 input-ip-address"/>


                                                                                </Form.Group>

                                                                                <div className="mx-3">〜</div>
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="text" {...register(`RangeIpRestriction.${index}.endIp`)}
                                                                                        className="input-ip-address"/>

                                                                                </Form.Group>

                                                                                {
                                                                                    index > 0 &&
                                                                                    <a onClick={() => {
                                                                                        setShowModalCfDeleteIp(true);
                                                                                        setCurrentIndexIp(index)
                                                                                    }}
                                                                                       className="btn-remove-setting btn-remove-ip-restrict position-relative">削除する</a>
                                                                                }
                                                                            </div>

                                                                            <div
                                                                                className="d-flex align-items-center">
                                                                                <ErrorMessage
                                                                                    name={`RangeIpRestriction_${index}_startIp`}
                                                                                    className={"px-0 pt-1 mb-0"}
                                                                                    errors={errors}/>
                                                                            </div>
                                                                        </div>

                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="d-flex align-items-center">
                                                        <Col xs={12} md={12} lg={12} xxl={3}>

                                                        </Col>
                                                        <Col xs={12} md={12} lg={12} xxl={6}>

                                                            {
                                                                getValues('UseRangeIpRestriction') == 1 &&
                                                                <div className="d-inline-block mt-4">

                                                                    <button type={'button'}
                                                                            onClick={() => appendRangeIpRestriction({
                                                                                startIp: '',
                                                                                endIp: '',
                                                                            })}
                                                                            className={'btn btn-screen-password d-flex align-items-center'}>
                                                                        <svg width="10" height="11" viewBox="0 0 10 11"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M5.90915 6.54288H9.65933C9.74924 6.54145 9.83506 6.5051 9.89864 6.44152C9.96222 6.37794 9.99857 6.29211 10 6.20221V5.52017C9.99857 5.43026 9.96222 5.34444 9.89864 5.28085C9.83506 5.21727 9.74924 5.18092 9.65933 5.17949H5.90915C5.87886 5.18131 5.84852 5.17668 5.82014 5.16591C5.79176 5.15514 5.76599 5.13847 5.74452 5.11701C5.72306 5.09555 5.70639 5.06977 5.69562 5.0414C5.68485 5.01302 5.68022 4.98268 5.68204 4.95238V1.20151C5.68061 1.11161 5.64426 1.02578 5.58068 0.9622C5.5171 0.898618 5.43127 0.862267 5.34137 0.86084H4.65933C4.56942 0.862267 4.4836 0.898618 4.42001 0.9622C4.35643 1.02578 4.32008 1.11161 4.31865 1.20151V4.95169C4.32047 4.98199 4.31584 5.01233 4.30507 5.0407C4.2943 5.06908 4.27763 5.09485 4.25617 5.11632C4.23471 5.13778 4.20893 5.15445 4.18056 5.16522C4.15218 5.17599 4.12184 5.18062 4.09154 5.1788H0.340673C0.250648 5.18023 0.164722 5.21668 0.101122 5.28041C0.0375211 5.34414 0.00124912 5.43014 0 5.52017V6.20221C0.00142736 6.29211 0.037778 6.37794 0.10136 6.44152C0.164942 6.5051 0.250766 6.54145 0.340673 6.54288H4.09085C4.12115 6.54106 4.15149 6.54569 4.17986 6.55646C4.20824 6.56723 4.23401 6.5839 4.25548 6.60536C4.27694 6.62683 4.29361 6.6526 4.30438 6.68098C4.31515 6.70935 4.31978 6.7397 4.31796 6.76999V10.5202C4.31939 10.6101 4.35574 10.6959 4.41932 10.7595C4.4829 10.8231 4.56873 10.8594 4.65863 10.8608H5.34067C5.43058 10.8594 5.51641 10.8231 5.57999 10.7595C5.64357 10.6959 5.67992 10.6101 5.68135 10.5202V6.76999C5.67952 6.73963 5.68417 6.70923 5.69498 6.6808C5.70579 6.65237 5.72252 6.62656 5.74406 6.60509C5.7656 6.58362 5.79146 6.56696 5.81992 6.55624C5.84838 6.54551 5.8788 6.54096 5.90915 6.54288Z"
                                                                                fill="#0070D2"/>
                                                                        </svg>
                                                                        <span className="ms-2">追加</span>
                                                                    </button>


                                                                </div>

                                                            }


                                                        </Col>
                                                    </Row>
                                                    <Row className="d-flex align-items-center">
                                                        <Col xs={12} md={12} lg={12} xxl={12}>
                                                            <div
                                                                className="d-flex align-items-center">
                                                                <ErrorMessage
                                                                    name={'UseRangeIpRestriction'}
                                                                    className={"px-0 pt-1 mb-0"}
                                                                    errors={errors}/>
                                                            </div>

                                                        </Col>

                                                    </Row>


                                                </div>
                                            </div>


                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            className="d-flex justify-content-center p-4 d-grid gap-5 position-relative">
                                            <Button type="submit" size="sm"
                                                    className={`border px-4 btn-save`}>
                                                {
                                                    isSubmitting && <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                }
                                                この端末を更新
                                            </Button>
                                            {
                                                selectedSettings?.Id != 0 &&
                                                <a onClick={() => setShowModalCfDelete(true)}
                                                   className="btn-remove-setting">この端末を削除する</a>
                                            }


                                        </ListGroup.Item>


                                    </ListGroup>
                                </Form>
                            }
                        </Card>

                    </Card>


                </div>
            }
        </Fragment>
    )

}