import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Button, Card, Form, ListGroup, Spinner} from "react-bootstrap";
import {TsAdminContext} from "../../context/ts-admin";
import {ErrorMessage} from "../../parts/error-message";
import {useForm} from "react-hook-form";
import {getMessage, useYupValidationResolver} from "../../common";
import * as yup from "yup";
import {getListEmail, updateListEmail} from "../../api-service/email";
import {toast} from "react-toastify";


const reMail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function EmailList() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSetting, setDataSetting] = useState<any>(null);
    const validationSchema = yup.object().shape({
        email1: yup.string().trim().transform(value => value === '' ? undefined : value).matches(reMail, getMessage('fe01')),
        email2: yup.string().trim().transform(value => value === '' ? undefined : value).matches(reMail, getMessage('fe01')),
        email3: yup.string().trim().transform(value => value === '' ? undefined : value).matches(reMail, getMessage('fe01')),

    });
    const {
        register,
        handleSubmit,
        reset,
        formState: {isSubmitting, isDirty, errors, isSubmitted, dirtyFields}
    } = useForm({
        resolver: useYupValidationResolver(validationSchema),
        defaultValues: {
            email1: "",
            email2: "",
            email3: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    const fetchListEmail = useCallback(async () => {
        if (orgInfo && orgInfo.currentOrg) {
            await getListEmail({}).then((data: any) => {
                reset(data.Setting)
                setDataSetting(data);
                setIsLoading(false);
            }).catch(e => {
                setIsLoading(false);
            });
        }
    }, [orgInfo]);


    useEffect(() => {
        fetchListEmail();
    }, [orgInfo])

    const submitFormData = async (data) => {
        if (
            (data.email1 == data.email2 && data.email1 != undefined && undefined !== data.email2) ||
            (data.email3 == data.email2 && data.email3 != undefined && undefined !== data.email2) ||
            (data.email1 == data.email3 && data.email1 != undefined && undefined !== data.email3)) {
            toast.error(getMessage('fe02'));
            return;
        }
        await updateListEmail(data).then((res) => {
            toast.success(getMessage('fe03'))
        }).catch(e => console.log(e))
    }

    return (
        <Fragment>
            <div className="log-admin">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">システム通知</Card.Body>
                </Card>
                <div className="mt-3">
                    {orgInfo && dataSetting && <Form onSubmit={handleSubmit(submitFormData)}>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="d-flex align-items-center">
                                <div className={'w-100 d-flex'}>
                                    <div className="w-25 py-2 item-label">通知先メールアドレス1</div>
                                    <div key="inline-radio" className="w-75">
                                        <div className={'w-100 d-flex'}>
                                            <Form.Control className="w-50" size="sm"
                                                          maxLength={255}
                                                          {...register(`email1`)}
                                            />
                                        </div>
                                        <div className={'w-100 d-flex'}>
                                            {isSubmitted && <ErrorMessage name='email1' errors={errors}/>}
                                        </div>
                                    </div>
                                </div>

                            </ListGroup.Item>

                            <ListGroup.Item className="d-flex align-items-center">
                                <div className={'w-100 d-flex'}>
                                    <div className="w-25 py-2 item-label">通知先メールアドレス2</div>
                                    <div key="inline-radio" className="w-75">
                                        <div className={'w-100 d-flex'}>
                                            <Form.Control className="w-50" size="sm"
                                                          maxLength={255}
                                                          {...register(`email2`)}
                                            />
                                        </div>
                                        <div className={'w-100 d-flex'}>
                                            {isSubmitted && <ErrorMessage name='email2' errors={errors}/>}
                                        </div>
                                    </div>
                                </div>

                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex align-items-center">
                                <div
                                    className="w-25 py-2 item-label">通知先メールアドレス3
                                </div>
                                <div key="inline-radio" className="w-75">
                                    <div className={'w-100 d-flex'}>
                                        <Form.Control className="w-50" size="sm"
                                                      maxLength={255}
                                                      {...register(`email3`)}
                                        />
                                    </div>
                                    <div className={'w-100 d-flex'}>
                                        {isSubmitted && <ErrorMessage name='email3' errors={errors}/>}
                                    </div>
                                </div>
                            </ListGroup.Item>


                            <ListGroup.Item className="d-flex justify-content-center p-4 d-grid gap-5">
                                <Button type="submit" size="sm"
                                        disabled={isSubmitting}
                                        className={`border px-4 ${isSubmitting ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                                    {
                                        isSubmitting && <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                    更新
                                </Button>


                            </ListGroup.Item>
                        </ListGroup>
                    </Form>
                    }
                </div>


            </div>
        </Fragment>
    )
}