import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {format, formatISO, subDays} from "date-fns";
import leftButton from "../../assets/icons/jump_left.svg";
import leftButtonEnable from "../../assets/icons/jump_left_enable.svg";
import {IconArrow} from "../../parts/icon";
import rightButton from "../../assets/icons/jump_right.svg";
import rightButtonEnable from "../../assets/icons/jump_right_enable.svg";
import {TsAdminContext} from "../../context/ts-admin";
import {getLogReportsAdmin} from "../../api-service/log-reports";


export function LogAdmin() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);

    const [searchData, setSearchData] = useState({
        startDate: subDays(new Date(), 3),
        endDate: new Date(),
        type: "",
        content: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState<any>([]);
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const columns = [
        {
            dataField: 'time',
            text: '日時',
            sort: true
        }, {
            dataField: 'level',
            text: 'ログレベル',
            sort: true
        },
        {
            dataField: 'content',
            text: '内容',
            sort: false
        }];
    const changeStarDate = (date: any) => {
        setSearchData({
            ...searchData,
            startDate: date
        })
    }
    const changeEndDate = (date: any) => {
        setSearchData({
            ...searchData,
            endDate: date
        })
    }

    const getLogData = useCallback(async () => {
        if (orgInfo && orgInfo.currentOrg) {
            await getLogReportsAdmin({
                startDate: formatISO(searchData.startDate, {representation: "date"}),
                endDate: formatISO(searchData.endDate, {representation: "date"}),
                type: searchData.type,
                content: searchData.content
            }).then((data: any) => {
                setRecords(data.records);
                setCurrentItems(data.records.slice(0, itemsPerPage))
                setCurrentPage(1);
                setIsLoading(false);
            }).catch(e => {
                setIsLoading(false);
            });
        }
    }, [searchData, orgInfo]);

    useEffect(() => {
        const newOffset = currentPage * itemsPerPage;
        setCurrentItems(records.slice(newOffset - itemsPerPage, newOffset))
    }, [currentPage])

    useEffect(() => {
        setIsLoading(true);
        fetchLogReports();
    }, [orgInfo])

    const fetchLogReports = async () => {
        console.log(searchData);
        setIsLoading(true);
        await getLogData()
    }

    function isJsonString(str) {
        try {
            return typeof str === 'object';
        } catch (e) {
            return false;
        }
    }


    function getClassNameLogType(item) {
        let className = '';
        switch (item.type) {
            case 'ERROR': {
                className = 'error-level';
                break;
            }
            case 'WARN': {
                className = 'warn-level';
                break
            }
            default : {
                className = item.deviceId == 'batch' ? 'info-batch-level' : 'info-level'
            }
        }
        return className;
    }

    function getNameLogType(item) {
        let nameType = '';
        switch (item.type) {
            case 'ERROR': {
                nameType = 'Error';
                break;
            }
            case 'WARN': {
                nameType = 'Warn';
                break
            }
            default : {
                nameType = item.deviceId == 'batch' ? 'バッチInfo' : 'Info'
            }
        }
        return nameType;
    }

    return (
        <Fragment>
            <div className="log-admin">
                <Card className="mt-3 card-container">
                    <Card.Body className="page-title">{userInfo.isSuperAdmin ? '管理者ログ' : 'ログ確認'}
                        <span
                            className="page-sub-title">（ログ保管期間は180日）</span></Card.Body>
                </Card>
                <div className="mt-3">
                    <Form>
                        <Row xs={1} md={3} lg={5} className="d-flex align-items-center p-3">
                            <Col xs={12} md={8} lg={4} className="d-flex align-items-center">
                                <Form.Label className="mt-2 me-2 log-form-label" htmlFor="startDate">
                                    期間
                                </Form.Label>
                                <ReactDatePicker
                                    selected={searchData.startDate}
                                    maxDate={new Date()}
                                    dateFormat={"yyyy/MM/dd"}
                                    onFocus={e => e.target.blur()}
                                    className="form-control log-form-control"
                                    customInput={
                                        <input
                                            type="text"
                                            readOnly={true}
                                            id="startDate"
                                            placeholder=""
                                        />
                                    }
                                    onChange={changeStarDate}
                                />
                                <span className="px-2">〜</span>
                                <ReactDatePicker
                                    selected={searchData.endDate}
                                    maxDate={new Date()}
                                    minDate={searchData.startDate}
                                    className="form-control log-form-control"
                                    dateFormat={"yyyy/MM/dd"}
                                    onFocus={e => e.target.blur()}
                                    customInput={
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="endDate"
                                            placeholder=""
                                        />
                                    }
                                    onChange={changeEndDate}
                                />
                            </Col>
                            <Col xs={8} md={6} lg={3} className="d-flex align-items-center">
                                <Form.Label className="mt-2 me-2 log-form-label">
                                    内容
                                </Form.Label>
                                <Form.Control
                                    className="input-account-name"
                                    type="text"
                                    value={searchData.content}
                                    onChange={e => setSearchData({...searchData, content: e.target.value})}/>
                            </Col>
                            <Col xs={12} md={7} lg="auto" className="d-flex align-items-center">
                                <Form.Label className="mt-2 me-2 log-form-label" htmlFor="inlineFormInputGroup">
                                    ログレベル
                                </Form.Label>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check"
                                >
                                    <Form.Check.Input
                                        value=""
                                        type="radio"
                                        name="log-level"
                                        id="log-level-all"
                                        className="me-2"
                                        checked={searchData?.type === ""}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="log-level-all">全て</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check">
                                    <Form.Check.Input
                                        value="info"
                                        type="radio"
                                        name="log-level"
                                        id="log-level-info"
                                        className="me-2"
                                        checked={searchData?.type === "info"}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="log-level-info"
                                                      className="info-level">Info</Form.Check.Label>
                                </Form.Check>

                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check">
                                    <Form.Check.Input
                                        value="info_batch"
                                        type="radio"
                                        name="log-level"
                                        id="log-level-batch-info"
                                        className="me-2"
                                        checked={searchData?.type === "info_batch"}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="log-level-batch-info"
                                                      className="info-batch-level">バッチInfo</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check">
                                    <Form.Check.Input
                                        value="warn"
                                        type="radio"
                                        name="log-level"
                                        id="log-level-warn"
                                        className="me-2"
                                        checked={searchData?.type === "warn"}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="log-level-warn"
                                                      className="warn-level">Warn</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check"
                                >
                                    <Form.Check.Input
                                        value="error"
                                        type="radio"
                                        name="log-level"
                                        id="log-level-error"
                                        className="me-2"
                                        checked={searchData?.type === "error"}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="log-level-error"
                                                      className="error-level">Error</Form.Check.Label>
                                </Form.Check>
                            </Col>
                            <Col xs="auto" md="auto" lg="auto" className="align-items-center">
                                {
                                    isLoading ?
                                        <Button size={'sm'} className="button-search" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            検索...
                                        </Button>
                                        :
                                        <Button size={'sm'} className="button-search" onClick={fetchLogReports}>
                                            検索
                                        </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </div>
                {records?.length > 0 ?
                    <Table className="mt-3 rounded-0 table-logs">
                        <thead>
                        <tr>
                            {
                                columns.map((e, index) => {
                                    return (
                                        <th key={index} className={index === 2 ? "lg-column" : "xs-column"}>
                                            {e.text}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            currentItems.length > 0 && records.length > 0 && currentItems.map((e, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{format(new Date(e.createAt), `yyyy/MM/dd`)}<br/>{format(new Date(e.createAt), `HH:mm`)}
                                        </td>
                                        <td className="td-error-label">

                                            <span
                                                className={getClassNameLogType(e)}>
                                                {getNameLogType(e)}
                                            </span>
                                        </td>
                                        <td style={{wordBreak: "break-all"}}>{isJsonString(e.content.msg) ? JSON.stringify(e.content.msg) : e.content.msg}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                    :
                    <h6 className="text-center pt-5 fw-bold">{!isLoading ? 'データがありません。' : '検索中・・・・'}</h6>
                }
                {
                    records && records.length > 0 && <nav>
                        <ul className="pagination justify-content-center">
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(1)}>
                                    {currentPage === 1 ?
                                        <img src={leftButton}/>
                                        :
                                        <img src={leftButtonEnable}/>
                                    }
                                </a>
                            </li>
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                                    {currentPage === 1 ?
                                        <IconArrow type='left'/>
                                        :
                                        <IconArrow type='left' active={true}/>
                                    }
                                </a>
                            </li>
                            <li className="pt-2">（{currentPage}/{Math.ceil(records.length / itemsPerPage)}）</li>
                            <li className={currentPage === Math.ceil(records.length / itemsPerPage) ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                                    {currentPage === Math.ceil(records.length / itemsPerPage) ?
                                        <IconArrow type='right'/>
                                        :
                                        <IconArrow type='right' active={true}/>
                                    }
                                </a>
                            </li>
                            <li className={currentPage === Math.ceil(records.length / itemsPerPage) ? "page-item disabled" : "page-item"}>
                                <a className="page-link"
                                   onClick={() => setCurrentPage(Math.ceil(records.length / itemsPerPage))}>
                                    {currentPage === Math.ceil(records.length / itemsPerPage) ?
                                        <img src={rightButton}/>
                                        :
                                        <img src={rightButtonEnable}/>
                                    }
                                </a>
                            </li>
                        </ul>
                    </nav>
                }


            </div>
        </Fragment>
    )
}