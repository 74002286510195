import axios from "axios";
import {redirectUrlLogin} from "../common";

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
    baseURL: apiUrl,
});

api.interceptors.request.use((config) => {
    if(config.method == 'post' || config.method == 'put') {
        config.data.ActionType =  window.location.pathname
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const token = localStorage.getItem('access-token');
    if (error.response.status === 401 && token !== null) {
        redirectUrlLogin();
    }
    if (error.response.status === 405) {
        setTimeout(() => window.location.href = '/404', 1000)
    }
    return Promise.reject(error.response.data);
});
const createHeaders = () => {
    const token = localStorage.getItem('access-token');
    const sfEnvironmentType = localStorage.getItem('environment-type');
    const headers: any = {
        'Content-type': 'application/json; charset=UTF-8'
    };
    if (token !== null) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (sfEnvironmentType != null) {
        headers.SalesforceEnv = sfEnvironmentType;
    }
    return headers;
}

export const apiPost = (path, data) => {
    return new Promise((resolve, reject) => {
        api.post(path, data, {
            headers: createHeaders()
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => reject(error.message));
    });
}

export const apiGet = (path, data) => {
    return new Promise((resolve, reject) => {
        api.get(path, {
            params: data,
            headers: createHeaders()
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error)
        });
    });
}

export const apiPut = (path, data) => {
    return new Promise((resolve, reject) => {
        api.put(path, data, {
            headers: createHeaders()
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => reject(error));
    });
}