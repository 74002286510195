import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.addEventListener('storage', (event) => {
    if (event.storageArea == localStorage) {
        let token = localStorage.getItem('access-token');
        if(token == undefined) {
            console.log(localStorage.getItem('role'));
            if(localStorage.getItem('role') ) {
                const role = localStorage.getItem('role');
                window.location.href =  `/${role}/login`;
            } else {
                window.location.href =  `/user/login`;
            }
        }
    }
}, false);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <BrowserRouter>
            <ToastContainer position="top-right"
                            autoClose={5000}
            />
            <App/>
        </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
