import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Col, Nav, Row} from "react-bootstrap";
import {useEffect, useState} from "react";

export function LayoutAdmin(props) {
    const [currentTab, setCurrentTab] = useState(useLocation().pathname);

    useEffect( () => {
        const path = window.location.pathname;
        setCurrentTab(path);
    },[window.location.pathname])
    const menu = [
        {
            id: 1,
            name: "取引先一覧",
            path: ["/admin-console"],
        },
        {
            id: 2,
            name: "駅名マスタ",
            path: ["/admin/stations"],
        },
        {
            id: 3,
            name: "未登録駅",
            path: ["/admin/unknown-stations"],
        },
        {
            id: 4,
            name: "取引先登録（手動登録）",
            path: ["/admin/create-org"],
        },
        {
            id: 5,
            name: "管理者ログ",
            path: ["/admin/logs"],
        },
        {
            id: 6,
            name: "システム通知",
            path: ["/admin/email-list"],
        },
    ];

    const checkActiveCurrentUrl = (item) => {
        return currentTab.indexOf(item) > -1
    }
    return (
        <div>
            <Row className="org-info-content">
                <div className="admin-info-bar">TeamSpirit 打刻連携アドオン</div>
            </Row>
            <Row className="d-flex admin-main-content">
                <Col lg="2" md="3" xs="4" className="p-0 bg-white menu-content">
                    <Nav className="flex-column">
                        {menu.map(item => (
                            <div key={item.id}
                                    className={checkActiveCurrentUrl(item.path) ? "d-flex nav-item nav-item__selected" : "d-flex nav-item"}>
                                <div className="nav-pre"></div>
                                <Nav.Link
                                    as={Link}
                                    to={item.path[0]}
                                    className="nav-suf"
                                    disabled={checkActiveCurrentUrl(item.path)}
                                    onClick={() => setCurrentTab(item.path[0])}>
                                    {item.name}
                                </Nav.Link>
                            </div>
                        ))}
                    </Nav>
                </Col>
                <Col lg="10" md="9" xs="8" className="settings-content scroll-content admin-scroll-content">
                    <Outlet/>
                </Col>
            </Row>
        </div>
    );
}