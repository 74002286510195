import {apiGet, apiPost, apiPut} from "./api";

export const getOrgInfo = async (orgId) => {
    return await apiGet(`organizations/${localStorage.getItem('target-org') || orgId}`, null);
}

export const updateOrg = async (orgId, data) => {
    return await apiPut(`organizations/${orgId}`, data);
}

export const getPasswordTargetOrg = async (orgId) => {
    return await apiGet(`organizations/${localStorage.getItem('target-org') || orgId}/password`, null);
}