import {Fragment, useContext, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {Button, Form, Row, Spinner} from "react-bootstrap";
import {TsAdminContext} from "../../context/ts-admin";
import {ErrorMessage} from "../../parts/error-message";
import {updateOrg} from "../../api-service/org-info";


export function QrPasswordSetting() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const [showModal, setShowModal] = useState(false);
    const [useQrPassword, setUseQrPassword] = useState(1);





    const handleCloseModal = () => {
        setShowModal(false);
    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted, dirtyFields}
    } = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            reNewPassword: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    useEffect(() => {
        if (orgInfo.currentOrg) {
            const currentOrg = orgInfo.currentOrg
            let isUsePasswordValue = '0';

            if (currentOrg.QrCodePassword !== undefined && currentOrg.QrCodePassword !== '') {
                setUseQrPassword(1);
                isUsePasswordValue = '1';
            } else {
                setUseQrPassword(0);
            }

            //const isUsePasswordValue = orgInfo.currentOrg.AdminPassword === '' ? '0' : '1'

        }
    }, [orgInfo])


    useEffect(() => {
        clearErrors();
        setValue("newPassword", '', {shouldDirty: true})
        setValue("oldPassword", '', {shouldDirty: true})
        setValue("reNewPassword", '', {shouldDirty: true})
    }, [])

    const submitFormData = async (data) => {
        await updateOrg(orgInfo.currentOrg.OrgId, {
            QrCodePassword: data.newPassword || '',
            OldQrCodePassword: data.oldPassword || ''
        }).then((res: any) => {
            /*
                * đã thay đổi từ không dùng mật khẩu thành có dùng mật khẩu
                パスワードを利用しないから利用するに変更しました。
                đã thay đổi từ dùng mật khẩu thành không dùng mật khẩu
                パスワードを利用するから利用しないに変更しました。
                đã đăng ký mật khẩu
                パスワードを登録しました。
                đã update mật khẩu
                パスワードを更新しました。
             */

            if (orgInfo.currentOrg.QrCodePassword != undefined) {
                if (data.isUsePassword == '0') {
                    toast.success("パスワードを利用するから利用しないに変更しました。");
                } else {
                    let message = data.oldPassword !== '' ? "パスワードを更新しました。" : "パスワードを利用しないから利用するに変更しました。";
                    toast.success(message);
                }
            } else {
                toast.success("パスワードを登録しました。");
            }
            setOrgInfo({...orgInfo, currentOrg: res.currentOrgInfo.Attributes});
            clearErrors();
            setShowModal(false);
        }).catch(err => {
            toast.error(err.message.error);
        }).finally(() => reset({
            oldPassword: "",
            newPassword: "",
            reNewPassword: "",
        }))
    }

    return (
        <Fragment>

            <Modal size='lg' className="pt-5 form-password-qr" backdrop='static' show={showModal}
                   onHide={handleCloseModal}>
                <Form onSubmit={handleSubmit(submitFormData)}>
                    <Modal.Header closeButton>
                        ログイン関連-パスワード設定・変更
                    </Modal.Header>
                    <Modal.Body>
                        <p>QRコード打刻設定の全ての端末の共通パスワードとなります。</p>

                        {
                            useQrPassword === 1 && <Row className="mb-3">
                                <div className="w-25 py-2 item-label">現在のパスワード</div>
                                <div key="inline-radio" className="w-75">
                                    <Row className="flex align-items-center">
                                        <div className="w-50">
                                            <Form.Control className="w-100" size="sm"
                                                          maxLength={8}
                                                          type={'password'}
                                                          {...register(`oldPassword`)}
                                            />
                                            {isSubmitted && <ErrorMessage name='oldPassword' className={"mb-0"} errors={errors}/>}
                                        </div>

                                    </Row>

                                </div>
                            </Row>
                        }


                        <Row>
                            <div className="w-25 py-2 item-label">{useQrPassword === 1 ? '新しいパスワード' : 'パスワード'}</div>
                            <div key="inline-radio" className="w-75">
                                <Row className="flex align-items-center">
                                    <div className="w-50">
                                        <Form.Control className="w-100" size="sm"
                                                      type={'password'}
                                                      maxLength={8}
                                                      {...register(`newPassword`)}
                                        />
                                        {isSubmitted && <ErrorMessage name='newPassword' errors={errors}/>}
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-0">4桁から8桁までの数字が使用できます</p>
                                    </div>
                                </Row>

                            </div>
                        </Row>

                        <Row className="mt-3">
                            <div
                                className="w-25 py-2 item-label">{useQrPassword === 1 ? '新しいパスワード（確認用）' : 'パスワード（確認用）'}</div>
                            <div key="inline-radio" className="w-75">
                                <Row className="d-flex align-items-center">
                                    <div className="w-50">
                                        <Form.Control className="w-100" size="sm"
                                                      maxLength={8}
                                                      type={'password'}
                                                      {...register(`reNewPassword`)}
                                        />
                                        {isSubmitted && <ErrorMessage name='reNewPassword' errors={errors}/>}
                                    </div>
                                    <div className="w-50">
                                        <p className="mb-0">確認のため、同じパスワードを入力してください</p>
                                    </div>
                                </Row>
                            </div>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                                onClick={handleCloseModal}>
                            キャンセル
                        </Button>
                        <Button type="submit" size="sm"
                                disabled={(isSubmitting)}
                                className={`border text-white px-4 ${(isSubmitting) ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                            {
                                isSubmitting && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                            設定
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>

            {
                orgInfo &&
                <Fragment>
                    <div className="w-25 py-2 item-label">ログイン関連</div>
                    <div key="inline-radio" className="w-50">
                        <p className="mb-1 fs-13">組織情報：{orgInfo.currentOrg?.QrCodeId}</p>
                        <p className="mb-0 fs-13">パスワード：
                            <button type={'button'} onClick={() => setShowModal(true)}
                                    className={'btn btn-screen-password'}>パスワード設定・変更
                            </button>
                        </p>
                    </div>


                </Fragment>
            }

        </Fragment>
    )

}