import leftButton from "../assets/icons/jump_left.svg";
import leftButtonEnable from "../assets/icons/jump_left_enable.svg";
import {IconArrow} from "./icon";
import rightButton from "../assets/icons/jump_right.svg";
import rightButtonEnable from "../assets/icons/jump_right_enable.svg";
import {Fragment} from "react";

export function PaginationComponent({totalPage, handlePage, currentPage}) {
    return <Fragment>
        <nav>
            <ul className="pagination justify-content-center">
                <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                    <a className="page-link" onClick={() => handlePage('start-page')}>
                        {currentPage === 1 ?
                            <img src={leftButton}/>
                            :
                            <img src={leftButtonEnable}/>
                        }
                    </a>
                </li>
                <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                    <a className="page-link" onClick={() => handlePage('prev-page')}>
                        {currentPage === 1 ?
                            <IconArrow type='left'/>
                            :
                            <IconArrow type='left' active={true}/>
                        }
                    </a>
                </li>
                <li className="pt-2">（{currentPage}/{totalPage}）</li>
                <li className={currentPage === totalPage ? "page-item disabled" : "page-item"}>
                    <a className="page-link" onClick={() => handlePage('next-page')}>
                        {currentPage === totalPage ?
                            <IconArrow type='right'/>
                            :
                            <IconArrow type='right' active={true}/>
                        }
                    </a>
                </li>
                <li className={currentPage === totalPage ? "page-item disabled" : "page-item"}>
                    <a className="page-link" onClick={() => handlePage('end-page')}>
                        {currentPage === totalPage ?
                            <img src={rightButton}/>
                            :
                            <img src={rightButtonEnable}/>
                        }
                    </a>
                </li>
            </ul>
        </nav>
    </Fragment>



}