import {Fragment, useContext, useEffect, useState} from "react";
import "../../App.css";
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import {TsAdminContext} from "../../context/ts-admin";
import {useAuth} from "../../provider";
import {Navigate, useLocation} from "react-router-dom";

export function Index(props: any) {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const location = useLocation();
    let auth = useAuth();

    const logout = async () => {
        await auth.signout(() => {
        });
    }
    return (
        <Fragment>
            <div className='org-info'>
                {!props.isLoading ? (
                    <div className='org-info__items'>
                        <div id='account-name' className='d-flex align-items-center'>
                            <div className="item-label pe-2">顧客名</div>
                            <div className="item-value">{orgInfo?.currentAccount?.AccountName}</div>
                        </div>
                        <div id='org-id' className='d-flex align-items-center'>
                            <div className="item-label pe-2">組織ID</div>
                            <div className="item-value">{orgInfo?.currentOrg?.OrgId}</div>
                        </div>
                        <div id='org-type' className='d-flex align-items-center'>
                            <div className="item-label pe-2">組織種別</div>
                            <div
                                className="item-value">{orgInfo?.currentOrg?.EnvironmentType === 1 ? 'Sandbox' : '本番組織'}</div>
                        </div>
                        <div id='license-number' className='d-flex align-items-center'>
                            <div className="item-label pe-2">使用/契約数</div>
                            <div
                                className="item-value">{orgInfo?.usedLicenseNumber}<span>/</span>{orgInfo?.currentAccount?.LicenseNumber}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='org-info__items'>
                        <div className='d-flex align-items-center'>
                            <Skeleton width={200}/>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Skeleton width={200}/>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Skeleton width={100}/>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Skeleton width={100}/>
                        </div>
                    </div>
                )}
                {(!userInfo?.isSuperAdmin) &&
                    <div>
                        <a className='logout-btn' onClick={logout}>ログアウト</a>
                    </div>
                }
            </div>
        </Fragment>
    )

}