import {Fragment, useContext, useEffect, useState} from "react";
import "../../App.css";
import {TsAdminContext} from "../../context/ts-admin";
import {Button, Form, InputGroup, ListGroup, Spinner} from "react-bootstrap";
import {format} from "date-fns";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {MESSAGE_PASSWORD_VALIDATE_FORMAT, MESSAGE_RE_PASSWORD_NOT_MATCH, useYupValidationResolver} from "../../common";
import {ErrorMessage} from "../../parts/error-message";
import {updateOrg} from "../../api-service/org-info";
import {toast} from "react-toastify";
import {concat} from "lodash";
import Modal from "react-bootstrap/Modal";


export function PasswordSettingPage({
                                        changeModeProvider
                                    }) {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    const oldPasswordSchemaObject = yup.object().shape({
        oldPassword: yup.string().trim().transform(value => value === '' ? undefined : value)
            .required("現在のパスワードを入力してください。")
            .matches(/^[0-9]*$/, MESSAGE_PASSWORD_VALIDATE_FORMAT)
            .min(4, MESSAGE_PASSWORD_VALIDATE_FORMAT)
            .max(10, MESSAGE_PASSWORD_VALIDATE_FORMAT),
    });


    const validationSchema = yup.object().shape({
        newPassword: yup.string().trim().transform(value => value === '' ? undefined : value).when("isUsePassword", (isUsePassword, schema) => {
            if (isUsePassword.length > 0 && isUsePassword[0] == 1)
                return schema.required("パスワードを入力してください。").matches(/^[0-9]*$/, MESSAGE_PASSWORD_VALIDATE_FORMAT)
                    .min(4, MESSAGE_PASSWORD_VALIDATE_FORMAT)
                    .max(10, MESSAGE_PASSWORD_VALIDATE_FORMAT)
            return schema
        }),
        reNewPassword: yup.string().when("isUsePassword", (isUsePassword, schema) => {
            if (isUsePassword.length > 0 && isUsePassword[0] == 1)
                return schema.required("パスワード（確認）を入力してください。")
            return schema
        }).oneOf([yup.ref('newPassword'), ''], MESSAGE_RE_PASSWORD_NOT_MATCH),
    });

    const [currentSchema, setCurrentSchema] = useState(validationSchema)
    const [dataAdminPassword, setDataAdminPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    }

    const [useAdminPassword, setUseAdminPassword] = useState(1);
    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        clearErrors,
        formState: {isSubmitting, isDirty, errors, isSubmitted, dirtyFields}
    } = useForm({
        resolver: useYupValidationResolver(currentSchema),
        defaultValues: {
            oldPassword: "",
            isUsePassword: "0",
            newPassword: "",
            reNewPassword: "",
        },
        mode: "onBlur",
        reValidateMode: "onChange"

    });

    useEffect(() => {
        if (orgInfo.currentOrg) {
            const currentOrg = orgInfo.currentOrg
            let isUsePasswordValue = '0';

            if (currentOrg.AdminPassword !== undefined && currentOrg.AdminPassword !== '') {
                setUseAdminPassword(1);
                isUsePasswordValue = '1';
            } else {
                setUseAdminPassword(0);
            }
            if (currentOrg.AdminPassword) {
                setCurrentSchema(validationSchema.concat(oldPasswordSchemaObject))
            }
            //const isUsePasswordValue = orgInfo.currentOrg.AdminPassword === '' ? '0' : '1'
            reset(formValues => ({
                ...formValues,
                isUsePassword: isUsePasswordValue,
            }))
        }
    }, [orgInfo])

    const watchIsUsePassword = watch("isUsePassword");

    useEffect(() => {
        clearErrors();
        setValue("newPassword", '', { shouldDirty: true })
        setValue("oldPassword", '', { shouldDirty: true })
        setValue("reNewPassword", '', { shouldDirty: true })
    }, [watchIsUsePassword])

    console.log(isDirty, dirtyFields, getValues(),isSubmitting)

    const submitFormData = async (data) => {
        if(userInfo.isSuperAdmin) {
            setShowModal(true);
            setDataAdminPassword(data);
        } else {
            await updateAdminPassword(data);
        }

    }
    const updateAdminPassword = async (data) => {
        await updateOrg(orgInfo.currentOrg.OrgId, {
            AdminPassword: data.newPassword || '',
            OldPassword: data.oldPassword || ''
        }).then((res: any) => {
            /*
                * đã thay đổi từ không dùng mật khẩu thành có dùng mật khẩu
                パスワードを利用しないから利用するに変更しました。
                đã thay đổi từ dùng mật khẩu thành không dùng mật khẩu
                パスワードを利用するから利用しないに変更しました。
                đã đăng ký mật khẩu
                パスワードを登録しました。
                đã update mật khẩu
                パスワードを更新しました。
             */
            if (orgInfo.currentOrg.AdminPassword != undefined) {
                if (data.isUsePassword == '0') {
                    toast.success("パスワードを利用するから利用しないに変更しました。");
                } else {
                    console.log(data.oldPassword);
                    let message = data.oldPassword !== '' ? "パスワードを更新しました。" : "パスワードを利用しないから利用するに変更しました。";
                    toast.success(message);
                }
            } else {
                toast.success(" パスワードを登録しました。");
            }
            setOrgInfo({...orgInfo, currentOrg: res.currentOrgInfo.Attributes});
            changeModeProvider();
        }).catch(err => {
            console.log(err);
            toast.error(err.message.error);
        }).finally(() => reset({}, {keepValues: true}))
    }

    const checkIsDisableButton = () => {
        const tempDirtyFields = {...dirtyFields}
        delete tempDirtyFields.isUsePassword;
        console.log(Object.keys(tempDirtyFields));
        if(Object.keys(tempDirtyFields).length > 0) {
            return false;
        }
        return true;
    }
    const adminUpdatePassword = async () => {
        setIsLoading(true);
        await updateAdminPassword(dataAdminPassword).finally(() => {
            setIsLoading(false);
            setShowModal(false);
        });
    }

    return (
        <Fragment>
            <Modal className="pt-5" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>更新してもよろしいでしょうか？</Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn" onClick={handleCloseModal}>
                        キャンセル
                    </Button>
                    {
                        isLoading ?
                            <Button variant="primary" size="sm" type="button" className="modal-btn" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                解除...
                            </Button>
                            :
                            <Button variant="primary" size="sm" type="button"
                                    onClick={adminUpdatePassword}
                                    className="modal-btn">
                                更新
                            </Button>
                    }

                </Modal.Footer>
            </Modal>
            {
                orgInfo && <Form onSubmit={handleSubmit(submitFormData)}>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex align-items-center">
                            <div className="w-25 py-2 item-label">現在の設定</div>
                            <div key="inline-radio" className="w-50 fs-13">
                                {useAdminPassword ? '利用する' : '利用しない'}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex align-items-center">
                            <div className="w-25 py-2 item-label">パスワードの利用有無</div>
                            <div key="inline-radio" className="w-75">
                                <Form.Check
                                    inline
                                    label="利用しない"
                                    {...register(`isUsePassword`)}
                                    type="radio"
                                    value={0}
                                    className="checkbox-label mb-0 radio-form-check"
                                />
                                <Form.Check
                                    inline
                                    label="利用する"
                                    {...register(`isUsePassword`)}
                                    type="radio"
                                    value={1}
                                    className="checkbox-label mb-0 radio-form-check"
                                />
                            </div>
                        </ListGroup.Item>
                        {
                            useAdminPassword === 1 && <ListGroup.Item className="d-flex align-items-center">
                                <div className={'w-100 d-flex'}>
                                    <div className="w-25 py-2 item-label">現在のパスワード</div>
                                    <div key="inline-radio" className="w-75">
                                        <div className={'w-100 d-flex'}>
                                            <Form.Control className="w-50" size="sm"
                                                maxLength={10}
                                                type={'password'}
                                                {...register(`oldPassword`)}
                                            />
                                        </div>
                                        <div className={'w-100 d-flex'}>
                                            {isSubmitted && <ErrorMessage name='oldPassword' errors={errors}/>}
                                        </div>
                                    </div>
                                </div>

                            </ListGroup.Item>
                        }
                        <ListGroup.Item className="d-flex align-items-center">
                            <div className={'w-100 d-flex'}>
                                <div className="w-25 py-2 item-label">{useAdminPassword === 1 ? '新しいパスワード' : 'パスワード'}</div>
                                <div key="inline-radio" className="w-75">
                                    <div className={'w-100 d-flex'}>
                                        <Form.Control className="w-50" size="sm"
                                            type={'password'}
                                            maxLength={10}
                                            disabled={getValues('isUsePassword') == 0}
                                            {...register(`newPassword`)}
                                        />
                                        <p className='hint'>4桁から10桁までの数字が使用できます。</p>
                                    </div>
                                    <div className={'w-100 d-flex'}>
                                        {isSubmitted && <ErrorMessage name='newPassword' errors={errors}/>}
                                    </div>
                                </div>
                            </div>

                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex align-items-center">
                            <div
                                className="w-25 py-2 item-label">{useAdminPassword === 1 ? '新しいパスワード（確認）' : 'パスワード（確認）'}</div>
                            <div key="inline-radio" className="w-75">
                                <div className={'w-100 d-flex'}>
                                    <Form.Control className="w-50" size="sm"
                                        disabled={getValues('isUsePassword') == 0}
                                        maxLength={10}
                                        type={'password'}
                                        {...register(`reNewPassword`)}
                                    />
                                    <p className='hint'>確認のため、同じパスワードを入力してください。</p>
                                </div>
                                <div className={'w-100 d-flex'}>
                                    {isSubmitted && <ErrorMessage name='reNewPassword' errors={errors}/>}
                                </div>
                            </div>
                        </ListGroup.Item>


                        <ListGroup.Item className="d-flex justify-content-center p-4 d-grid gap-5">
                            <Button type="submit" size="sm"
                                    disabled={(checkIsDisableButton() || isSubmitting)}
                                    className={`border px-4 ${(checkIsDisableButton() || isSubmitting) ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                                {
                                    isSubmitting && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                                設定
                            </Button>

                            <Button
                                onClick={changeModeProvider} type="button" variant="light"
                                className={`text-btn-back-password`}
                                size="sm">
                                キャンセル
                            </Button>

                        </ListGroup.Item>
                    </ListGroup>
                </Form>
            }

        </Fragment>
    )
}