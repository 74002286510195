import {apiGet, apiPost, apiPut} from "./api";

export const getPatterns = async (searchParams) => {
    return await apiGet(`/pittouch-ex/patterns`, searchParams);
}

export const getCompanies = async (searchParams) => {
    return await apiGet(`/pittouch-ex/companies`, searchParams);
}

export const updateDataCompany = async (data) => {
    return await apiPost(`/pittouch-ex/companies`, data);
}

export const createNewPattern = async (data) => {
    return await apiPost(`/pittouch-ex/patterns`, data);
}

export const doEditDataPattern = async (id, data) => {
    return await apiPut(`/pittouch-ex/pattern/${id}`, data);
}